import styled from 'styled-components'

export const CouponCodeWrapper = styled.div`
  padding: 24px;
  display: flex;
`

export const CouponCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 368px;
  width: 100%;
`

export const CouponCodeButtonContainer = styled.div``
