import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useShowTaxesOnCheckout } from '~/hooks/useShowTaxesOnCheckout'
import { readSignatureSummary, writeSignatureSummary } from '~/services/api'
import { ISignatureSummaryResponse } from '~/types/index'
import { formatInstallmentText } from '~/utils/index'

export const useSignatureSummaryContent = () => {
  const { showTaxesOnCheckout } = useShowTaxesOnCheckout()
  const [signatureSummaryData, setSignatureSummaryData] = useState<ISignatureSummaryResponse['response']>()
  const { isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [shouldShowMore, setShouldShowMore] = useState(false)

  useEffect(() => {
    async function findSignatureSummaryData() {
      const result = await readSignatureSummary()

      if (typeof result != 'string') {
        const sortedPaymentFlow = result?.response?.financingSummary?.paymentFlow?.sort?.((a) => (a?.isUpfront ? -1 : 1))
        const newSignatureSummaryData = { ...result.response, paymentFlow: sortedPaymentFlow }

        setSignatureSummaryData(newSignatureSummaryData)
      }
    }

    findSignatureSummaryData()
  }, [])

  const handleNextScreen = useCallback(async () => {
    await writeSignatureSummary()
  }, [])

  const summaryInstallmentsToApply = formatInstallmentText(signatureSummaryData?.financingSummary?.installmentsToApply || 0)

  const handleToggle = useCallback(() => setShouldShowMore((toggle) => !toggle), [setShouldShowMore])

  return {
    financingSummaryData: signatureSummaryData?.financingSummary,
    contractSummaryData: signatureSummaryData?.contractSummary,
    ccbDraft: signatureSummaryData?.ccbDraft,
    summaryInstallmentsToApply,
    shouldShowMore,
    handleToggle,
    handleNextScreen,
    isLoadingData,
    isSendingData,
    showTaxesOnCheckout,
  }
}
