import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormScreenNTypes } from '~/types/index'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readFormScreenN, writeFormScreenN } from '~/services/api'

export const useFormScreenN = () => {
  const { step, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [questions, setQuestions] = useState<FormScreenNTypes.IQuestion[] | null>(null)
  const [answers, setAnswers] = useState<FormScreenNTypes.IAnswers | null>(null)

  console.table(answers)

  const selectAnswersFromBackend = useCallback(
    (questionsData: FormScreenNTypes.IQuestion[]) => {
      questionsData.forEach((questionData) => {
        if (questionData.value) {
          setAnswers((prevAnswers) => {
            return {
              ...prevAnswers,
              [questionData.questionId]: questionData.value,
            }
          })

          if (questionData.options) {
            questionData.options.forEach((option) => {
              if (option.value === questionData.value) {
                if (option.extraIfSelected) {
                  option.extraIfSelected.forEach((extraQuestion) => {
                    setAnswers((prevAnswers) => {
                      return {
                        ...prevAnswers,
                        [extraQuestion.questionId]: extraQuestion.value,
                      }
                    })
                  })
                }
              }
            })
          }
        }
      })
    },
    [setAnswers],
  )

  useEffect(() => {
    const fetchQuestions = async () => {
      const data = await readFormScreenN(step)
      if (typeof data !== 'string') {
        setQuestions(data?.response?.questions)
        selectAnswersFromBackend(data?.response?.questions)
      }
    }
    fetchQuestions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnswers, setQuestions, selectAnswersFromBackend, step])

  const handleChange = useCallback(
    (questionId: number, value: string | string[] | boolean | number) => {
      const newAnswers = {
        ...answers,
        [questionId]: value,
      }

      const chosenQuestionWithExtraIfSelected = questions?.find((question) => question.questionId === questionId)

      // future todo if necessary: convert this logic to a recursive function
      // removes extraIfSelected answers from answers if parent question is changed
      if (chosenQuestionWithExtraIfSelected?.options) {
        chosenQuestionWithExtraIfSelected?.options?.forEach?.((chosenQuestion) => {
          chosenQuestion.extraIfSelected?.forEach?.((extraQuestion) => {
            delete newAnswers[extraQuestion.questionId]
          })
        })
      }

      setAnswers(newAnswers)
    },
    [answers, questions, setAnswers],
  )

  const getAnswerValue = useCallback(
    (questionId: number) => {
      return answers ? answers[questionId] : ''
    },
    [answers],
  )

  const getDropdownQuestion = useCallback(
    (questionId: number) => {
      const allNestedQuestions: FormScreenNTypes.IQuestion[] = []

      // pushes all nested chosen questions to allNestedQuestions array
      for (const question of questions) {
        if (question.questionId === questionId) {
          allNestedQuestions.push(question)
        }

        if (question.options) {
          question.options.forEach((option) => {
            if (option.extraIfSelected) {
              option.extraIfSelected.forEach((extraQuestion) => {
                if (extraQuestion.questionId === questionId) {
                  allNestedQuestions.push(extraQuestion)
                }
              })
            }
          })
        }
      }

      return allNestedQuestions.find((question) => question.questionId === questionId)
    },
    [questions],
  )

  const getDropdownValue = useCallback(
    (questionId: number) => {
      const drowdownQuestion = getDropdownQuestion(questionId)

      return drowdownQuestion?.options?.find((option) => option.value === getAnswerValue(questionId))
    },
    [getAnswerValue, getDropdownQuestion],
  )

  const toggleCheckbox = useCallback(
    (questionId: number, value: string) => {
      const checkboxQuestion = questions.find((q) => q.questionId === questionId)

      if (checkboxQuestion?.type === 'checkbox') {
        const currentValue = (getAnswerValue(questionId) as string[]) || []
        const newValue = currentValue.includes(value) ? currentValue.filter((v) => v !== value) : [...currentValue, value]

        handleChange(questionId, newValue)
      }
    },
    [questions, handleChange, getAnswerValue],
  )

  const handleSubmit = async () => {
    if (isFormValid) {
      await writeFormScreenN(step, answers)
    }
  }

  const isFormValid = useMemo(() => {
    const requiredQuestions = questions?.filter((question) => question.isRequired)
    let isValid = true

    if (requiredQuestions) {
      for (const question of requiredQuestions) {
        const answer = getAnswerValue(question.questionId)
        const isText = ['shortText', 'longText'].includes(question.type)
        const isTextValid = (answer as string)?.trim?.()?.length >= 5

        if (!answer || (isText && !isTextValid)) {
          isValid = false
        }
      }
    }
    return isValid
  }, [getAnswerValue, questions])

  return {
    questions,
    handleChange,
    getAnswerValue,
    getDropdownValue,
    isLoadingData,
    isSendingData,
    toggleCheckbox,
    handleSubmit,
    isFormValid,
  }
}
