import styled from 'styled-components'
import { loadingCSS } from '~/styles/global'

export const IntroGuarantorWrapper = styled.div`
  width: 100%;
`

export const IntroGuarantorLoading = styled.div`
  ${loadingCSS}

  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 24px;
  width: 368px;
  max-width: calc(100vw - 48px);
  height: 100px;
  border-radius: 4px;
`
