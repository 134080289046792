import { useMemo } from 'react'
import { ProgressBar, theme } from '@provi/provi-components'
import LoadingInput from '~/components/molecules/LoadingInput'
import { useGuarantorAnalysisContent } from './hooks'
import * as S from './style'

const GuarantorAnalysisContent = () => {
  const { screenWaitingTime, remainingTimeInPercentage, remainingTime, isLoadingData, guarantorCPF } =
    useGuarantorAnalysisContent()

  const renderGuarantorData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <S.GuarantorAnalysisContainer>
          <LoadingInput />
        </S.GuarantorAnalysisContainer>
      )
    }

    return (
      <S.GuarantorAnalysisContainer>
        <S.CPFInput
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="CPF"
          name="CPF"
          width="368px"
          value={guarantorCPF}
          mask="999.999.999-99"
          readOnly
          isValid={true}
        />
        {!!screenWaitingTime && !!remainingTimeInPercentage && (
          <S.ProgressBarWrapper>
            <ProgressBar
              progress={remainingTimeInPercentage}
              borderRadius="4px"
              height="16px"
              width="368px"
              backgroundColor={theme.colors.bluePrimaryLight}
              color={theme.colors.bluePrimary}
            />
            <S.GuarantorAnalysisContentText>{`A análise será concluída em ${remainingTime} segundos...`}</S.GuarantorAnalysisContentText>
          </S.ProgressBarWrapper>
        )}
      </S.GuarantorAnalysisContainer>
    )
  }, [isLoadingData, guarantorCPF, screenWaitingTime, remainingTimeInPercentage, remainingTime])

  return (
    <S.GuarantorAnalysisContentWrapper>
      <S.GuarantorAnalysisContentTitle>Indicação de avalista</S.GuarantorAnalysisContentTitle>
      {renderGuarantorData}
    </S.GuarantorAnalysisContentWrapper>
  )
}

export default GuarantorAnalysisContent
