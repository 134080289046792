import { ButtonV3 } from '@provi/provi-components'
import { useMemo } from 'react'
import LoadingInput from '~/components/molecules/LoadingInput'
import { SingleQuestion } from '~/components/organisms/Isa/SingleQuestion'
import { useFormScreenN } from './hooks'
import * as S from './style'

export const FormScreenN = () => {
  const { questions, handleChange, getAnswerValue, getDropdownValue, isLoadingData, handleSubmit, isSendingData, isFormValid } =
    useFormScreenN()

  const renderLoading = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <>
          <LoadingInput inputWidth={'368px'} />
          <LoadingInput inputWidth={'368px'} />
        </>
      )
    }

    return null
  }, [isLoadingData])

  return (
    <S.FormScreenNContainer>
      <S.FormScreenNContent>
        {isLoadingData
          ? renderLoading
          : questions?.map((question) => {
              return (
                <SingleQuestion
                  key={question.questionId}
                  question={question}
                  handleChange={handleChange}
                  getDropdownValue={getDropdownValue}
                  getAnswerValue={getAnswerValue}
                />
              )
            })}
      </S.FormScreenNContent>
      {!isLoadingData && (
        <ButtonV3
          text="Continuar"
          type="submit"
          onClick={handleSubmit}
          icon
          isLoading={isSendingData}
          disabled={!isFormValid}
        />
      )}
    </S.FormScreenNContainer>
  )
}
