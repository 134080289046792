import styled from 'styled-components'
import { Body2, loadingCSS } from '~/styles/global'

export const GuarantorDeniedWrapper = styled.div``

export const GuarantorDeniedDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 368px;
  margin: 25px 24px 35px;
`

export const GuarantorDeniedDataTextBox = styled.div``

export const GuarantorDeniedDataText = styled(Body2)``

export const GuarantorDeniedLoading = styled.div`
  ${loadingCSS};

  width: 100%;
  max-width: 368px;
  height: 48px;

  border-radius: 8px;
`
