import styled from 'styled-components'
import { InputFieldV3, theme } from '@provi/provi-components'
import { Body1, loadingCSS } from '~/styles/global'

export const SelectProductWrapper = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
`

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin-bottom: 35px;
  gap: 10px;

  > div > div {
    width: 100%;
    max-width: 17.5rem;
    min-height: 100%;
  }

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;

    > div > div {
      max-width: 100%;
    }
  }
`

export const CardCourseWrapper = styled.div``

export const SelectProductTitle = styled(Body1)`
  width: 100%;
  margin-right: auto;

  ${theme.breakpoints.down('md')} {
    margin-top: 10px;
  }
`

export const SelectProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  div {
    width: initial;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;

    div {
      width: 100%;
    }
  }
`

export const Input = styled(InputFieldV3)``

export const Icon = styled.img`
  margin-right: 8px;
`

export const ProductsWrapperLoading = styled(ProductsWrapper)``

export const SelectProductHeaderLoading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;
`

export const ProductsLoading = styled.div`
  ${loadingCSS}

  height: 180px;
  border-radius: 8px;
`
