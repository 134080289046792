import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import useCountDown from '~/hooks/useCountdown'
import { readLoadingCPFAnalysis, writeIncompleteAnalysis, writeLoadingCPFAnalysis } from '~/services/api'
import { ILoadingCPFAnalysis } from '~/types/index'

export const useLoadingCPFAnalysis = () => {
  const [loadingData, setLoadingData] = useState<ILoadingCPFAnalysis | null>(null)
  const { step, setStep } = useContext(CheckoutContext)
  const [timer, setTimer] = useState(3000)

  const { actions } = useCountDown(timer, 1000)
  const { start } = actions

  const { timeLeft: timeLeftDeadLine, actions: actionsDeadLine } = useCountDown(40 * 1000, 1000)
  const { start: startDeadLine } = actionsDeadLine
  const [timeOut, setTimeLeft] = useState(false)

  const tryLoadingData = useCallback(async () => {
    await writeLoadingCPFAnalysis(step)
  }, [step])

  useEffect(() => {
    ;(async () => {
      const result = await readLoadingCPFAnalysis()

      if (typeof result !== 'string') {
        setLoadingData(result?.response)
        setTimer(result?.response?.screenWaitingTime)
      }
    })()
  }, [setLoadingData, setTimer])

  useEffect(() => {
    ;(async () => {
      const hasTimedOut = timeLeftDeadLine === 0

      if (hasTimedOut && !timeOut) {
        setTimeLeft(true)

        const endpoint = 'incomplete-finance-simulation'
        const fallbackStep = 'incompleteFinanceSimulation'

        const timeoutData = await writeIncompleteAnalysis(endpoint)

        if (typeof timeoutData !== 'string') {
          setStep(timeoutData.nextScreen || fallbackStep)
        }
      }
    })()
  }, [timeLeftDeadLine, setStep, timeOut])

  useEffect(() => {
    if (loadingData?.screenWaitingTime) {
      startDeadLine(loadingData?.screenWaitingTime)
    }

    if (loadingData?.timeBetweenRequests) {
      start()

      const writeTimerInterval = setInterval(tryLoadingData, loadingData?.timeBetweenRequests)
      return () => clearInterval(writeTimerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData?.screenWaitingTime, loadingData?.timeBetweenRequests, start, startDeadLine])

  return {
    loadingDescription: loadingData?.loadingText,
  }
}
