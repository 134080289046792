import { useCallback, useEffect, useMemo, useState } from 'react'
import { IReadFinancingSuccessResponse } from 'types'
import useCountDown from '~/hooks/useCountdown'
import { useInIframe } from '~/hooks/useInIframe'
import { readFinancingSuccess } from '~/services/api'
import { formatPlural } from '~/utils/formatPlural'
import { gtagPurchase } from '~/utils/gtag/gtagPurchase'

export const useFinancingSuccess = () => {
  const { isInIframe } = useInIframe()

  const [isChangingPage, setIsChangingPage] = useState(false)
  const [financingSuccessData, setFinancingSuccessData] = useState<IReadFinancingSuccessResponse | null>(null)
  const { courses, partnerName, hasUpfront, upfrontLink, screenWaitingTime, timeBetweenRequests } = financingSuccessData || {}

  const { timeLeft, actions } = useCountDown(screenWaitingTime, timeBetweenRequests)
  const { start } = actions

  const fetchFinancingSuccess = useCallback(async () => {
    const data = await readFinancingSuccess('financingSuccess')

    if (typeof data !== 'string') {
      setFinancingSuccessData(data.response)

      gtagPurchase({
        paymentInfo: {
          amount: null,
          method: 'CourseFinancing',
        },
        classObjects: data.response.courses.map((course) => {
          return {
            CourseClassId: null,
            CourseId: null,
            className: course.className,
            courseName: course.courseName,
            price: null,
          }
        }),
      })
    }
  }, [setFinancingSuccessData])

  useEffect(() => {
    start()
    fetchFinancingSuccess()
  }, [fetchFinancingSuccess, start])

  useEffect(() => {
    if (timeBetweenRequests && hasUpfront && !upfrontLink && timeLeft) {
      timeLeft % timeBetweenRequests === 0 && fetchFinancingSuccess()
    }
  }, [timeBetweenRequests, timeLeft, hasUpfront, upfrontLink, fetchFinancingSuccess])

  const coursesText = useMemo(() => {
    if (!courses) {
      return ''
    }

    const courseNames = courses.map((course) => course.courseName)

    return `${formatPlural(courseNames)} com ${partnerName}`
  }, [courses, partnerName])

  const defaultButtonProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  const defaultIframeButtonProps = {
    target: '_self',
  }

  const buttonProps = isInIframe ? defaultIframeButtonProps : defaultButtonProps

  return {
    coursesText,
    partnerName,
    hasUpfront,
    upfrontLink,
    buttonProps,
    isChangingPage,
    setIsChangingPage,
  }
}
