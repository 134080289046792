import { styled, Tooltip, TooltipProps } from '@material-ui/core'
import { theme } from '@provi/provi-components'

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme: muiTheme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.colors.bluePrimaryDark,
    color: theme.colors.white100,
    maxWidth: 300,
    fontSize: muiTheme.typography.pxToRem(12),
    fontFamily: 'inherit',
    padding: '8px 12px',
  },
}))
