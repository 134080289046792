import { theme } from '@provi/provi-components'

export const LoadingCompletedIcon = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68 36C68 53.6731 53.6731 68 36 68C18.3269 68 4 53.6731 4 36C4 18.3269 18.3269 4 36 4C53.6731 4 68 18.3269 68 36Z"
        stroke={theme.colors.bluePrimary}
        strokeWidth="8"
      />
      <path
        d="M44.2822 5.09038C49.1601 6.3974 53.6594 8.84031 57.4122 12.2194C61.165 15.5984 64.0648 19.8177 65.8746 24.5322C67.6843 29.2467 68.3526 34.3226 67.8247 39.3449C67.2968 44.3672 65.5879 49.1932 62.8375 53.4284"
        stroke={theme.colors.bluePrimary}
        strokeWidth="8"
      />
      <path
        d="M50.2812 27.0946L31.4688 45.9063L22.0625 36.5009"
        stroke={theme.colors.bluePrimary}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
