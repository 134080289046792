import { ButtonV3 as Button } from '@provi/provi-components'
import { useMemo } from 'react'
import { ButtonArrow } from '~/components/molecules'
import CardCourse from '../CardCourse'
import { useSelectProduct } from './hooks'
import * as S from './styles'
import { IIsaCourse } from '~/types/index'
import { IProductType } from '~/constants/productTypes'

const AvailableCardCourse = ({
  course,
  handleSelectCourse,
  choosedCourseById,
  productType,
}: {
  course: IIsaCourse
  handleSelectCourse: (courseId: any) => void
  choosedCourseById: number | null
  productType: IProductType
}) => {
  return (
    <S.CardCourseWrapper onClick={() => handleSelectCourse(course.CourseId)}>
      <CardCourse
        courseId={course?.CourseId}
        classNameCourse={course?.courseName}
        details={course?.details}
        checkedRadio={choosedCourseById === course?.CourseId}
        price={course?.price}
        isProduct={productType === 'ISA'}
        discountPercentage={course?.discountPercentage}
        totalPriceToPay={course?.priceWithDiscount}
      />
    </S.CardCourseWrapper>
  )
}

export const SelectProduct = () => {
  const {
    isaCoursesData,
    isLoadingData,
    handleSelectCourse,
    handleSubmitButton,
    choosedCourseById,
    isSendingData,
    productType,
    search,
    handleSearch,
    handleOffset,
    limit,
    offset,
    hasNextPage,
    searchOffset,
  } = useSelectProduct()

  const renderProducts = useMemo(() => {
    return isaCoursesData?.courses?.length ? (
      isaCoursesData?.courses?.map((product) => (
        <AvailableCardCourse
          key={product?.CourseId}
          course={product}
          choosedCourseById={choosedCourseById}
          handleSelectCourse={handleSelectCourse}
          productType={productType}
        />
      ))
    ) : (
      <div className="space-y-2">
        <h3 className="text-xl">Ops!</h3>
        <p>Nenhum produto disponível no momento.</p>
      </div>
    )
  }, [isaCoursesData?.courses, choosedCourseById, handleSelectCourse, productType])

  return (
    <S.SelectProductWrapper>
      <S.SelectProductHeader>
        <S.SelectProductTitle>Selecione um produto</S.SelectProductTitle>
        <S.Input
          data-test-id="search-input"
          placeholder="Pesquisar"
          icon={<S.Icon src="/assets/svg/magnifying-glass.svg" alt="Pesquisa" />}
          width="288px"
          mobileWidth="100%"
          onChange={(e) => handleSearch(e.target.value)}
          value={search}
        />
      </S.SelectProductHeader>

      <div className="flex justify-end w-full mt-4 mb-2 space-x-2">
        <ButtonArrow
          handleClick={() => handleOffset('prev')}
          disabledArrow={isLoadingData || search ? searchOffset === 0 : offset === 0}
        />
        <ButtonArrow handleClick={() => handleOffset('next')} isRight disabledArrow={isLoadingData || !hasNextPage} />
      </div>

      <S.ProductsWrapper>
        {isLoadingData ? (
          <S.ProductsWrapperLoading>
            {Array.from({ length: limit }).map((_, index) => (
              <S.ProductsLoading key={index} />
            ))}
          </S.ProductsWrapperLoading>
        ) : (
          renderProducts
        )}
      </S.ProductsWrapper>

      {choosedCourseById && isaCoursesData?.courses?.length ? (
        <div className="fixed bottom-0 left-0 z-1 w-full py-4 px-6 bg-[rgba(255,255,255,0.8)] md:relative md:bottom-auto md:left-auto md:w-auto md:p-0 md:bg-none">
          <Button
            icon
            text="Começar"
            onClick={handleSubmitButton}
            disabled={!choosedCourseById || isLoadingData || isSendingData}
            isLoading={isSendingData}
            mobileWidth="100%"
          />
        </div>
      ) : null}
    </S.SelectProductWrapper>
  )
}
