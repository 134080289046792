import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readIntroIsa, writeIntroIsa } from '~/services/api'
import { IIntroISAData } from '~/types/index'

export const useIntroIsa = () => {
  const { isLoadingData, isSendingData, step } = useContext(CheckoutContext)
  const [infosData, setInfosData] = useState<IIntroISAData>()

  useEffect(() => {
    async function getIntroIsa() {
      const result = await readIntroIsa()
      typeof result !== 'string' && setInfosData(result?.response)
    }
    getIntroIsa()
  }, [setInfosData])

  const proceed = useCallback(async () => {
    await writeIntroIsa(step)
  }, [step])

  return {
    proceed,
    isSendingData,
    infosData,
    isLoadingData,
  }
}
