import styled from 'styled-components'
import { Body2 } from '~/styles/global'

export const BasicInfoAnalysisWrapper = styled.div``

export const BasicInfoTimeout = styled.div``

export const BasicInfoTimeoutText = styled(Body2)`
  margin-top: 48px;
  padding: 0 24px;

  width: 100%;
  max-width: 368px;
`
