import { useCallback, useContext, useEffect, useState } from 'react'
import { IDataToShow, IReadIntroGuarantorResponse, IWriteIntroGuarantor } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readIntroGuarantor, writeIntroGuarantor } from '~/services/api'
import * as constants from './constants'

export const useIntroGuarantor = () => {
  const { step, setIsInCourseFinancingReviewScreen } = useContext(CheckoutContext)

  const [dataToShow, setDataToShow] = useState<IDataToShow | null>(null)
  const [introGuarantorData, setIntroGuarantorData] = useState<IReadIntroGuarantorResponse | null>(null)

  useEffect(() => {
    const fetchIntroGuarantor = async () => {
      const data = await readIntroGuarantor()

      if (typeof data !== 'string') {
        const introGuarantor = data.response
        setIntroGuarantorData(introGuarantor)

        if (introGuarantor.remainingAttempts === 0) {
          setDataToShow(constants.changePaymentForm)
          return
        }

        if (introGuarantor.numberOfAttempts === 0) {
          if (introGuarantor.canSelectNewPaymentMethod) {
            setDataToShow(constants.appointSecondGuarantorWithPaymentForm)
          } else {
            setDataToShow(constants.appointSecondGuarantorWithoutPaymentForm)
          }
          return
        }

        if (introGuarantor.canSelectNewPaymentMethod) {
          setDataToShow(constants.appointNewGuarantorWithPaymentForm)
        } else {
          setDataToShow(constants.appointNewGuarantorWithoutPaymentForm)
        }
      }
    }

    fetchIntroGuarantor()
  }, [])

  const handleClick = useCallback(
    async (selectNewPaymentMethod: boolean) => {
      let params: IWriteIntroGuarantor = {
        currentScreen: step,
      }

      if (selectNewPaymentMethod) {
        setIsInCourseFinancingReviewScreen(false)

        params = {
          ...params,
          data: {
            selectNewPaymentMethod,
          },
        }
      }

      await writeIntroGuarantor(params)
    },
    [step, setIsInCourseFinancingReviewScreen],
  )

  return { dataToShow, handleClick, introGuarantorData }
}
