import { InputFieldV3, SelectorV3, Radio, CheckBox } from '@provi/provi-components'
import { useMemo, useState } from 'react'
import { HtmlTooltip } from '~/components/atoms/HtmlTooltip'
import { FormScreenNTypes } from '~/types/index'
import * as S from './style'

interface ISingleQuestion {
  question: FormScreenNTypes.IQuestion
  handleChange: (id: number, value: string | number) => void
  getDropdownValue: (id: number | string) => FormScreenNTypes.ISelectOption
  getAnswerValue: (id: number | string) => FormScreenNTypes.IAnswersValue
}

export const SingleQuestion = ({ question, handleChange, getDropdownValue, getAnswerValue }: ISingleQuestion) => {
  const [isBlurred, setIsBlurred] = useState(false)

  const value = question.type === 'dropdown' ? getDropdownValue(question.questionId) : getAnswerValue(question.questionId)
  const extraQuestions = useMemo(() => {
    return question?.options?.find?.(
      // @ts-ignore - typescript is being dumb and not recognizing the types of ISelectOption
      (option) => option?.value === value?.value || option?.value === value,
    )?.extraIfSelected
  }, [question?.options, value])

  const extraQuestionsIfSelected = () => {
    if (!extraQuestions) {
      return null
    }

    return (
      <>
        {extraQuestions?.map((extraQuestion) => {
          return (
            <SingleQuestion
              key={extraQuestion.questionId}
              question={extraQuestion}
              handleChange={handleChange}
              getDropdownValue={getDropdownValue}
              getAnswerValue={getAnswerValue}
            />
          )
        })}
      </>
    )
  }

  const hasExtraInfo = question?.moreInfo?.title || question?.moreInfo?.description?.length

  const extraInfo = () => {
    if (!hasExtraInfo) {
      return
    }

    return (
      <>
        {question?.moreInfo?.title && (
          <HtmlTooltip
            title={
              <>
                <S.SingleQuestionTipTitle>{question?.moreInfo?.title}</S.SingleQuestionTipTitle>
                {question?.moreInfo?.description?.map((line, index) => (
                  <S.SingleQuestionTipText key={index}>{line}</S.SingleQuestionTipText>
                ))}
              </>
            }
            placement="left"
          >
            <S.SingleQuestionTip>?</S.SingleQuestionTip>
          </HtmlTooltip>
        )}
      </>
    )
  }

  const CheckboxToDisableInput = () => {
    if (question?.checkboxOption) {
      const isChecked = value === null

      return (
        <CheckBox
          name="checkbox"
          label={question.checkboxOption.label}
          text={question.checkboxOption.label}
          checked={isChecked}
          onChange={() => handleChange(question.questionId, isChecked ? '' : null)}
        />
      )
    }

    return null
  }

  if (['longText', 'shortText'].includes(question.type)) {
    const TextLabel = () => {
      return (
        <>
          {question.title}
          <br />
          {question?.description && <S.SingleQuestionDescription>{question?.description}</S.SingleQuestionDescription>}
        </>
      )
    }

    return (
      <S.SingleQuestionContent key={question.questionId} hasExtraInfo={!!hasExtraInfo}>
        <InputFieldV3
          label={<TextLabel />}
          name={question.title}
          placeholder={question.placeholder}
          onChange={(e) => handleChange(question.questionId, e.target.value)}
          value={value || ''}
          type="text"
          width="100%"
          as={question.type === 'shortText' ? 'input' : 'textarea'}
          className={question.type === 'shortText' ? 'short-text' : 'long-text'}
          disabled={value === null && question?.checkboxOption?.label}
          onBlur={() => setIsBlurred(true)}
          isValid={(value as string)?.trim?.()?.length >= 5 && value}
          hasError={isBlurred && question.isRequired && (value as string)?.trim?.()?.length < 5 && value}
          errorMessage="Você precisa digitar pelo menos 5 caracteres"
        />
        {extraInfo()}
        <CheckboxToDisableInput />
        {extraQuestionsIfSelected()}
      </S.SingleQuestionContent>
    )
  }

  if (question.type === 'dropdown') {
    return (
      <S.SingleQuestionContent key={question.questionId} hasExtraInfo={!!hasExtraInfo}>
        <SelectorV3
          key={question.questionId}
          label={question.title}
          explainer={question.description}
          name={question.title}
          options={question.options}
          placeholder={question.placeholder}
          id={question.questionId}
          onChange={(event) => {
            handleChange(question.questionId, event.value)
          }}
          value={value}
          isSearchable={false}
          width="100%"
        />
        {extraInfo()}
        {extraQuestionsIfSelected()}
      </S.SingleQuestionContent>
    )
  }

  if (question.type === 'radio') {
    const options = question.options.map((option, index) => {
      return (
        <Radio
          key={index}
          name={option.value}
          text={option.label}
          checked={value === option.value}
          onChange={() => {
            handleChange(question.questionId, option.value)
          }}
        />
      )
    })

    return (
      <S.SingleQuestionContent>
        <S.RadioContainer>
          <S.RadioTitle>{question.title}</S.RadioTitle>
          {question?.description && <S.SingleQuestionDescription>{question.description}</S.SingleQuestionDescription>}

          <div>{options}</div>
        </S.RadioContainer>
        {extraInfo()}
        {extraQuestionsIfSelected()}
      </S.SingleQuestionContent>
    )
  }

  return null
}
