import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, Caption, loadingCSS } from '~/styles/global'

export const CreditInAnalysisContainer = styled.div`
  width: fit-content;

  margin: 46px 0 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin: 24px;
  }
`

export const CreditInAnalysisText = styled(Body2)`
  max-width: 368px;
`

export const CreditInAnalysisLoadingBlock = styled.div`
  ${loadingCSS}

  width: 368px;
  max-width: calc(100vw - 48px);
  height: 96px;
  border-radius: 4px;
`

export const CreditInAnalysisIncompleteGuarantor = styled.div``

export const CreditInAnalysisSocialBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-top: 26px;
  margin-bottom: 48px;
  padding: 13px 12px 11px 12px;
  max-width: 472px;

  border: 2px solid ${theme.colors.bluePrimaryLight};
`

export const SocialBoxText = styled(Caption)<{ isLink?: boolean }>`
  ${({ isLink }) =>
    isLink &&
    css`
      color: ${theme.colors.bluePrimary};
      cursor: pointer;
    `}
`

export const CreditInAnalysisContent = styled.div`
  p:first-child {
    margin: 15px 0;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    gap: 8px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 20px;

  ${theme.breakpoints.down('md')} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
