import styled from 'styled-components'
import { Body1, loadingCSS, Caption } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
  }
`

export const WrapperInput = styled.form`
  margin-top: 22px;

  > div {
    padding-bottom: 24px;
  }
`

export const TitleForm = styled(Body1)``

export const ButtonWrapper = styled.div`
  @media (max-width: 500px) {
    > div {
      min-width: 100%;
    }
  }
`

export const WrapperInputLoading = styled(WrapperInput)`
  max-width: 368px;
  gap: 24px;

  > div {
    :nth-child(4) {
      width: 184px;
    }
  }

  ${theme.breakpoints.down('md')} {
    gap: 16px;
    max-width: 100%;

    > div {
      :nth-child(4) {
        width: 100%;
      }
    }
  }
`

export const BasicInfoLoadingButton = styled.div`
  height: 24px;
  max-width: 184px;
  margin-top: 16px;

  ${loadingCSS}
`

export const LoadingInputCheckbox = styled(BasicInfoLoadingButton)`
  min-width: 320px;

  ${theme.breakpoints.down('md')} {
    min-width: 100%;
  }
`

export const EmailTypoText = styled(Caption)`
  display: flex;
  margin-top: -24px;
  padding-bottom: 24px;
`

export const EmailTypoButton = styled(Caption)`
  cursor: pointer;
  color: ${theme.colors.bluePrimary};
  margin-left: 4px;

  :hover {
    color: ${theme.colors.bluePrimaryDark};
  }
`
