import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import useCountDown from '~/hooks/useCountdown'
import { readLoadingFinanceSimulation, writeIncompleteAnalysis, writeLoadingFinanceSimulation } from '~/services/api'
import { ILoadingFinanceSimulation } from '~/types/index'

export const useLoadingFinanceSimulation = () => {
  const { step, setStep } = useContext(CheckoutContext)
  const [isLoadingMessage, setIsLoadingMessage] = useState(true)
  const [loadingData, setLoadingData] = useState<ILoadingFinanceSimulation | null>(null)
  const { timeLeft: timeLeftDeadLine, actions: actionsDeadLine } = useCountDown(40 * 1000, 1000)
  const { start: startDeadLine } = actionsDeadLine
  const [timeOut, setTimeLeft] = useState(false)

  useEffect(() => {
    ;(async () => {
      const hasTimedOut = timeLeftDeadLine === 0

      if (hasTimedOut && !timeOut) {
        setTimeLeft(true)

        const endpoint = 'incomplete-finance-simulation'
        const fallbackStep = 'incompleteFinanceSimulation'

        const timeoutData = await writeIncompleteAnalysis(endpoint)

        if (typeof timeoutData !== 'string') {
          setStep(timeoutData.nextScreen || fallbackStep)
        }
      }
    })()
  }, [timeLeftDeadLine, setStep, timeOut])

  const tryLoadingMessage = useCallback(async () => {
    const writeData = await writeLoadingFinanceSimulation(step)

    if (typeof writeData !== 'string' && writeData?.nextScreen !== step) {
      setIsLoadingMessage(false)

      const timerInterval = setTimeout(() => {
        setStep(writeData?.nextScreen)
      }, 2000)

      return () => clearInterval(timerInterval)
    }
  }, [step, setStep, setIsLoadingMessage])

  useEffect(() => {
    ;(async () => {
      const result = await readLoadingFinanceSimulation()

      if (typeof result !== 'string') {
        setLoadingData(result?.response)
      }
    })()
  }, [setLoadingData])

  useEffect(() => {
    if (loadingData?.screenWaitingTime) {
      startDeadLine(loadingData?.screenWaitingTime)
    }

    if (loadingData?.timeBetweenRequests) {
      const writeTimerInterval = setInterval(tryLoadingMessage, loadingData?.timeBetweenRequests)
      return () => clearInterval(writeTimerInterval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData?.timeBetweenRequests, startDeadLine])

  return {
    isLoadingMessage,
    firstName: loadingData?.firstName,
  }
}
