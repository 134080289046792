export const formatPlural = (texts?: string[]) => {
  if (!texts) {
    return ''
  }

  const last = texts.pop()
  const rest = texts.join(', ')

  return `${rest}${rest.length ? ' e ' : ''}${last}`
}
