import { useCallback, useContext, useEffect, useState } from 'react'
import useCountDown from '~/hooks/useCountdown'
import { readGuarantorAnalysis, writeGuarantorAnalysis, writeIncompleteGuarantorAnalysis } from '~/services/api'
import { IReadGuarantorAnalysisResponse } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const useGuarantorAnalysisContent = () => {
  const { step, setStep, isLoadingData } = useContext(CheckoutContext)
  const [requestData, setRequestData] = useState<IReadGuarantorAnalysisResponse | null>(null)
  const [timer, setTimer] = useState(40000)

  const { timeLeft, actions } = useCountDown(timer, 1000)
  const { start } = actions

  const tryGuarantorAnalysis = useCallback(async () => {
    const writeData = await writeGuarantorAnalysis(step)
    typeof writeData !== 'string' && writeData.nextScreen != step && setStep(writeData.nextScreen)
  }, [step, setStep])

  useEffect(() => {
    const fetchGuarantorAnalysisData = async () => {
      const response = await readGuarantorAnalysis()

      if (typeof response !== 'string') {
        setRequestData(response.response)
        setTimer(response.response.screenWaitingTime)
      }
    }

    fetchGuarantorAnalysisData()
  }, [])

  useEffect(() => {
    if (requestData && requestData.timeBetweenRequests) {
      start()

      const timerInterval = setInterval(tryGuarantorAnalysis, requestData.timeBetweenRequests)
      return () => clearInterval(timerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData, start])

  const remainingTime = Math.floor(timeLeft / 1000)
  const remainingTimeInPercentage = Math.floor((timeLeft / timer) * 100)

  const guarantorCPF = requestData?.guarantorCPF

  const hasTimedOut = remainingTime > -1 && remainingTime <= 0

  useEffect(() => {
    if (hasTimedOut) {
      const tryIncompleteGuarantorAnalysis = async () => {
        await writeIncompleteGuarantorAnalysis()
      }

      tryIncompleteGuarantorAnalysis()
    }
  }, [hasTimedOut])

  return {
    screenWaitingTime: (requestData?.screenWaitingTime || 30000) / 1000,
    remainingTimeInPercentage,
    remainingTime,
    isLoadingData,
    guarantorCPF,
  }
}
