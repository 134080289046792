import { KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { createAddressInfo, readAddressInfo } from '~/services/api'
import { validateCEP } from '~/utils/validation'
import { removeMask } from '~/utils/removeMask'
import { ICepPromise, IPopulateInputsAddress } from 'types'
import { brazilianStates } from './states'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import cep from 'cep-promise'

export const useAddressTab = () => {
  const { isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [isInputLoading, setIsInputLoading] = useState(false)
  const streetNumberInputRef = useRef<HTMLInputElement>(null)
  const nbhInputRef = useRef<HTMLInputElement>(null)

  const proceed = useCallback(async (valuesBasicInfo) => {
    const removeMaskCEP = removeMask(valuesBasicInfo.zipcode)

    const asArrayBasicInfo = Object.entries(valuesBasicInfo).filter(([key, value]) => value && key !== 'zipcode')
    const filteredObject = asArrayBasicInfo.reduce((obj, entry) => ({ ...obj, [entry[0]]: entry[1] }), {})

    await createAddressInfo({
      ...filteredObject,
      zipcode: removeMaskCEP,
    } as IPopulateInputsAddress)
  }, [])

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    isValid,
    dirty,
    validateForm,
    validateField,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      zipcode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      streetNumber: '',
      complement: '',
    },

    validationSchema: Yup.object({
      zipcode: Yup.string().required(requiredErrorMessages.cep),
      street: Yup.string().required(requiredErrorMessages.street).min(4, errorMessages.street),
      streetNumber: Yup.string().required(requiredErrorMessages.streetNumber),
      state: Yup.string().required(requiredErrorMessages.state),
      city: Yup.string().required(requiredErrorMessages.city).min(3, errorMessages.city),
      district: Yup.string().required(requiredErrorMessages.district).min(3, errorMessages.district),
      complement: Yup.string().notRequired().nullable(),
    }),

    onSubmit: proceed,

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'zipcode' && fields[cur] && !validateCEP(fields[cur]) && removeMask(fields[cur]).length !== 8) {
          return {
            ...prev,
            [cur]: errorMessages.cep,
          }
        }

        return {
          ...prev,
        }
      }, {})
    },
  })

  useEffect(() => {
    if (values.zipcode && values.zipcode.length === 9) {
      setIsInputLoading(true)

      cep(values.zipcode)
        .then((data: ICepPromise) => {
          setFieldValue('state', data?.state || '')
          setFieldValue('neighborhood', data?.neighborhood || '')
          setFieldValue('district', data?.neighborhood || '')
          setFieldValue('city', data?.city || '')
          setFieldValue('street', data?.street || '')

          data.state && setFieldTouched('state')
          data.neighborhood && setFieldTouched('neighborhood')
          data.neighborhood && setFieldTouched('district')
          data.city && setFieldTouched('city')
          data.street && setFieldTouched('street')

          setIsInputLoading(false)

          if (!!data?.state && !!data?.city && !data?.street) {
            nbhInputRef.current?.focus()
            return
          }

          if (data?.street) {
            streetNumberInputRef.current?.focus()
            return
          }
        })
        .catch(() => {
          document.getElementById('react-select-2-input')?.focus()
        })
        .finally(() => setIsInputLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.zipcode, setIsInputLoading])

  useEffect(() => {
    if (values?.streetNumber?.length === 1) {
      setFieldTouched('streetNumber')
      validateField('streetNumber')
      validateForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.streetNumber])

  const populateInputs = useCallback(
    ({ zipcode, city, state, district, street, streetNumber, complement }: IPopulateInputsAddress) => {
      setFieldValue('zipcode', zipcode)
      setFieldValue('state', state || '')
      setFieldValue('city', city)
      setFieldValue('district', district)
      setFieldValue('street', street)
      setFieldValue('streetNumber', streetNumber)
      setFieldValue('complement', complement)

      zipcode && setFieldTouched('zipcode')
      state && setFieldTouched('state')
      city && setFieldTouched('city')
      district && setFieldTouched('district')
      street && setFieldTouched('street')
      streetNumber && setFieldTouched('streetNumber')
      complement && setFieldTouched('complement' || '')

      validateForm()
    },
    [setFieldValue, setFieldTouched, validateForm],
  )

  const brazilianStatesSelector = brazilianStates.find((option) => option.acronym === values.state)

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  useEffect(() => {
    async function getInfosAddress() {
      const result = await readAddressInfo()

      if (typeof result != 'string') {
        populateInputs(result?.response)
      }
    }

    getInfosAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getObjectFromAcronym = useCallback(
    (acronym: string) => {
      const filteredObject = brazilianStates.find((option) => option.acronym === acronym)
      return setFieldValue('state', filteredObject?.acronym)
    },
    [setFieldValue],
  )

  return {
    values,
    brazilianStatesSelector,
    errors,
    touched,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    handleEnterKey,
    setFieldTouched,
    validateForm,
    streetNumberInputRef,
    isLoadingData,
    isSendingData,
    nbhInputRef,
    getObjectFromAcronym,
    isInputLoading,
  }
}
