import { useCallback, useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { writeCheckoutStatus } from '~/services/api'

export const usePixForm = () => {
  const { chosenPaymentMethod, paymentInformationData, step, isSendingData } = useContext(CheckoutContext)
  const { cartData } = useContext(ParamsContext)

  const onSubmitButton = useCallback(async () => {
    if (!chosenPaymentMethod || !paymentInformationData?.response.Pix?.paymentConditions[0].PartnerConditionId) {
      return
    }
    await writeCheckoutStatus({
      currentScreen: step,
      data: {
        paymentMethod: chosenPaymentMethod,
        PartnerConditionId: paymentInformationData?.response.Pix?.paymentConditions[0].PartnerConditionId,
      },
    })
  }, [chosenPaymentMethod, paymentInformationData, step])

  return {
    onSubmitButton,
    cartData,
    chosenPaymentMethod,
    isSendingData,
  }
}
