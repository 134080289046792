import styled from 'styled-components'
import { Caption } from '~/styles/global'

export const InputLabelWithDescriptionContainer = styled.span`
  display: flex;
  flex-direction: column;
`

export const InputLabelWithDescriptionLabel = styled.span`
  display: flex;
  flex-direction: column;
`

export const InputLabelWithDescriptionDescription = styled(Caption)`
  display: flex;
  flex-direction: column;
`
