import { useMemo, useRef } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'

import { SuccessIcon, ShutterIcon, UploadIcon } from '~/components/atoms'
import { Loading } from '~/components/molecules/LoadingRing/style'
import { useInsertDocument } from './hooks'
import * as S from './style'
import { useWindowSize } from '~/utils/useWindowSize'
import Webcam from 'react-webcam'
import { toast } from 'react-toastify'

const InsertDocument = () => {
  const videoRef = useRef<Webcam>(null)
  const { width } = useWindowSize()

  const isDesktop = useMemo(() => (width && width > 800) || false, [width])

  const {
    getTextToDisplay,
    captureImage,
    isWebcamOpen,
    documentImage,
    retryImage,
    confirmImage,
    isSuccessful,
    isLoading,
    hasNextStep,
    callOpenFilePicker,
    completedDocumentFlow,
    continueFlow,
    isSendingData,
    unicoFailed,
  } = useInsertDocument({
    videoRef,
  })

  const renderDefaultButtons = useMemo(() => {
    return (
      <S.ReviewButtons>
        {isDesktop && (
          <Button
            onClick={callOpenFilePicker}
            text="Enviar foto"
            width="184px"
            mobileWidth="100%"
            buttonProps={{
              endIcon: <UploadIcon />,
            }}
          />
        )}
        <Button
          color="white"
          onClick={() => {
            retryImage()
          }}
          text="Abrir câmera"
          width="fit-content"
          mobileWidth="100%"
        />
      </S.ReviewButtons>
    )
  }, [isDesktop, callOpenFilePicker, retryImage])

  const renderConfirmButtons = useMemo(() => {
    return (
      <>
        <Button
          onClick={() => confirmImage(isDesktop)}
          text="Confirmar"
          width="184px"
          mobileWidth="100%"
          isLoading={isSendingData}
        />
        <Button color="white" onClick={() => retryImage()} text="Tirar outra" width="fit-content" mobileWidth="100%" />
      </>
    )
  }, [isDesktop, confirmImage, retryImage, isSendingData])

  const renderContinueButton = useMemo(() => {
    return <Button onClick={continueFlow} text="Continuar" width="184px" mobileWidth="100%" icon isLoading={isSendingData} />
  }, [continueFlow, isSendingData])

  const renderSuccessfulButtons = useMemo(() => {
    if (isDesktop) {
      if (completedDocumentFlow) {
        return renderContinueButton
      }

      if (isSuccessful && hasNextStep) {
        return renderDefaultButtons
      }

      return renderConfirmButtons
    }

    return (
      <Button
        onClick={() => {
          continueFlow()

          if (hasNextStep) {
            retryImage()

            return
          }
          confirmImage(isDesktop)
        }}
        text={hasNextStep ? 'Tirar próxima foto' : 'Continuar'}
        width="100%"
        mobileWidth="100%"
        icon
      />
    )
  }, [
    continueFlow,
    completedDocumentFlow,
    isDesktop,
    confirmImage,
    hasNextStep,
    isSuccessful,
    renderConfirmButtons,
    renderDefaultButtons,
    renderContinueButton,
    retryImage,
  ])

  const renderReview = useMemo(() => {
    return (
      <S.RenderReview>
        <S.ImageReviewContainer>
          <S.ImageReviewContainerInner>
            <S.ImageReviewOverlay shouldShow={isLoading || isSuccessful} isLoading={isLoading}>
              {isLoading ? <Loading /> : <SuccessIcon isVariant={true} />}
            </S.ImageReviewOverlay>

            <S.InnerImageContainer data-recording-disable>
              <S.ImageReview src={documentImage || ' '} />
            </S.InnerImageContainer>
          </S.ImageReviewContainerInner>

          <S.ReviewText>{getTextToDisplay()}</S.ReviewText>
          {documentImage ? (
            <S.ReviewButtons isSingleButton={completedDocumentFlow}>
              {isSuccessful ? renderSuccessfulButtons : renderConfirmButtons}
            </S.ReviewButtons>
          ) : (
            renderDefaultButtons
          )}
        </S.ImageReviewContainer>
      </S.RenderReview>
    )
  }, [
    completedDocumentFlow,
    documentImage,
    isSuccessful,
    isLoading,
    getTextToDisplay,
    renderDefaultButtons,
    renderSuccessfulButtons,
    renderConfirmButtons,
  ])

  const renderCamera = useMemo(() => {
    if (unicoFailed) {
      return (
        <S.RenderCamera>
          <S.InsertDocumentVideo
            // @ts-ignore
            ref={videoRef}
            videoConstraints={{
              facingMode: 'environment',
              width: { ideal: 4096 },
              height: { ideal: 2160 },
            }}
            mirrored={false}
            audio={false}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize={true}
            onUserMediaError={() => {
              toast.error('Você precisa permitir o acesso a câmera para continuar')
            }}
          />
          <S.InsertDocumentButton onClick={captureImage} id="camera--trigger">
            <ShutterIcon />
          </S.InsertDocumentButton>
          <S.DesktopButtonOverlay />
        </S.RenderCamera>
      )
    }

    return <div id="box-camera" />
  }, [videoRef, captureImage, unicoFailed])

  return (
    <S.InsertDocumentWrapper hasPicture={documentImage ? true : false}>
      {isWebcamOpen && renderCamera}
      {renderReview}
    </S.InsertDocumentWrapper>
  )
}

export default InsertDocument
