import { theme } from '@provi/provi-components'
import Webcam from 'react-webcam'
import styled, { css } from 'styled-components'
import { Body2 } from '~/styles/global'

interface IImageReviewOverlay {
  shouldShow: boolean
  isLoading: boolean
}

interface IInsertDocumentWrapper {
  hasPicture: boolean
}

export const InsertDocumentWrapper = styled.div<IInsertDocumentWrapper>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  #box-camera {
    z-index: 101;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;

    ${theme.breakpoints.down('md')} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border-radius: 0;
      height: ${({ hasPicture }) => (hasPicture ? 'auto' : '100vh')};
      object-fit: cover;
    }
  }
`

export const InsertDocumentVideo = styled(Webcam)`
  z-index: 100;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  border-radius: 16px;

  ${theme.breakpoints.down('md')} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 0;
    height: 100vh;
    object-fit: cover;
  }
`

export const InsertDocumentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 102;
  position: relative;
  bottom: calc(-100vh + 120px);
  transform: translate(-50%, -50%);
  left: 50%;

  width: 56px;
  height: 56px;
  background: ${theme.colors.white100};
  border-radius: 50%;
  border: 1px solid ${theme.colors.white100};
  outline: none;
  cursor: pointer;

  :focus {
    border: 1px solid ${theme.colors.bluePrimary};
  }

  :active {
    background: ${theme.colors.bluePrimaryLight};
    border: 1px solid ${theme.colors.bluePrimary};
  }

  ${theme.breakpoints.up('md')} {
    bottom: unset;
    top: -71px;

    transform: translateX(-50%);
  }
`

export const ImageReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  background: ${theme.colors.white100};
  padding: 24px;

  ${theme.breakpoints.up('md')} {
    align-self: flex-start;
    position: relative;

    max-width: 368px;
    padding: unset;
    margin: 24px;
  }
`

export const ImageReviewContainerInner = styled.div`
  background-color: ${theme.colors.grey500};

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100%;
  height: 276px;
  max-width: 100%;
  overflow: hidden;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    height: 222px;
    max-width: 312px;
    max-height: 312px;
  }
`

export const InnerImageContainer = styled.div``

export const ImageReviewOverlay = styled.div<IImageReviewOverlay>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ isLoading }) => (isLoading ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.75)')};
  transition: all 0.4s ease-in-out;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 64px;
    height: 64px;
  }

  ${({ shouldShow, isLoading }) =>
    (shouldShow || isLoading) &&
    css`
      opacity: 1;

      svg {
        animation: draw-svg 0.5s ease-out;
        stroke-dasharray: 100;

        @keyframes draw-svg {
          0% {
            stroke-dashoffset: -100;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
    `}
`

export const ImageReview = styled.img`
  image-rendering: optimizeQuality;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;

  width: 100%;
  height: 276px;
  object-fit: scale-down;

  background: ${theme.colors.greyLight};

  ${theme.breakpoints.down('md')} {
    max-width: 312px;
    max-height: 312px;
    width: 100%;
    height: 100%;
  }
`

export const ReviewText = styled(Body2)`
  width: 100%;
`

export const ReviewButtons = styled.div<{ isSingleButton?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  gap: 13px;
  margin-bottom: 100px;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    flex-direction: ${({ isSingleButton }) => (isSingleButton ? 'row' : 'row-reverse')};
    justify-content: space-between;
    max-width: 368px;
    gap: unset;
    margin-bottom: 0;

    div {
      width: fit-content;
    }
  }
`

export const DesktopButtonOverlay = styled.div`
  position: absolute;
  width: 100%;

  background: ${theme.colors.black100};
  z-index: 101;
  opacity: 0.67;
  height: 80px;
  bottom: 60px;
  overflow: hidden;
  border-radius: 0 0 16px 16px;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const RenderReview = styled.div`
  width: 100%;
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${theme.breakpoints.down('md')} {
    height: 100vh;
  }
`

export const RenderCamera = styled.div`
  width: 100%;
  position: absolute;
  z-index: 101;
`
