import { useContext } from 'react'
import { createPortal } from 'react-dom'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const InScreenError = () => {
  const { inPageError, step } = useContext(CheckoutContext)

  if (!inPageError || step !== 'singlePageCheckout') {
    return null
  }

  return createPortal(
    <div className="rounded-xl overflow-hidden h-full ">
      <div className="bg-orange-600 pt-16 md:pt-8 p-6 text-white text-lg">{inPageError?.errorBannerObject?.title}</div>
      <div className="flex flex-col gap-4 bg-white p-6 min-h-full">
        <p>{inPageError?.errorBannerObject?.description}</p>
        <div className="flex flex-col">
          <h2 className="font-bold">{inPageError?.errorBannerObject?.bulletPointTitle}</h2>
          <ul>
            {inPageError?.errorBannerObject?.bulletPoints?.map((bulletPoint, index) => (
              <li key={index} className="list-disc list-inside pl-2">
                {bulletPoint}
              </li>
            ))}
          </ul>
          <button
            className="w-fit text-xs text-brand-primary font-semibold py-4 hover:text-brand-primaryDark transition-all"
            onClick={() => {
              const element = document.getElementById('payment-methods-text')
              element?.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            Ir para método de pagamento
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('error-portal'),
  )
}
