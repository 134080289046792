import { IReadCreditInAnalysisResponse } from 'types'
import * as S from './style'

export const formatSocialText = (content: IReadCreditInAnalysisResponse) => {
  const texts = [
    `Olá, ${content.data.guarantorName},`,
    `Aqui é a Principia, uma empresa de crédito estudantil.`,
    `${content.data.userName} indicou você para ser avalista de um financiamento. Como avalista, você vai ajudar ${
      content.data.userName.split(' ')[0]
    } a cursar ${content.data.courseNames.map((course: string) => course).join(', ')} com ${content.data.partnerName}.`,
    `Para dar continuidade ao processo, você deve preencher seus dados nesse link:`,
    `${content.data.guarantorURL}`,
    `É fácil, seguro e pode transformar a vida de ${content.data.userName.split(' ')[0]}!`,
    `Vamos lá?`,
  ]

  const htmlTexts = texts.map((text) => {
    if (text.includes('http')) {
      return (
        <S.SocialBoxText isLink={true} as="a" href={text} key={text} target="_blank" rel="noopener noreferrer">
          {text}
        </S.SocialBoxText>
      )
    }

    return <S.SocialBoxText key={text}>{text}</S.SocialBoxText>
  })

  const sharableText = texts.join('\n')

  const guarantorURL = content.data.guarantorURL || ''

  return {
    htmlTexts,
    sharableText,
    guarantorURL,
  }
}
