import { useMemo } from 'react'
import CheckoutSuccess from '~/components/templates/CheckoutSuccess'
import { buttonParamsByType } from './buttonParamsByType'
import { useInvalidPath } from './hooks'

export const InvalidPath = () => {
  const { invalidPathData, showButton, buttonType } = useInvalidPath()

  const renderDescriptions = useMemo(() => {
    return invalidPathData?.descriptions.map((description, index) => {
      return (
        <p key={index} className="even:py-1">
          {description}
        </p>
      )
    })
  }, [invalidPathData?.descriptions])

  return (
    <CheckoutSuccess
      isSuccess={false}
      title={invalidPathData?.title}
      text={renderDescriptions}
      isWithButton={showButton}
      hrefURL={buttonParamsByType[buttonType]?.hrefURL}
      buttonText={buttonParamsByType[buttonType]?.label}
    />
  )
}
