import { useState, useEffect, useContext } from 'react'
import { IContractInfo } from '~/types/index'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readContractSummary, writeContractSummary } from '~/services/api'
/**
 * @name useContractSummary
 * @returns { contractData, signContract, isLoadingData, isSendingData }
 * @description Custom hook for contract summary
 */
export const useContractSummary = () => {
  const { step, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [contractData, setData] = useState<IContractInfo>()

  /**
   * @name getContractInfo
   * @description Get all the contract information and change states
   */
  const getContractInfo = async () => {
    const contractInformations = await readContractSummary(step)
    if (typeof contractInformations !== 'string') {
      setData(contractInformations)
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    getContractInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @name signContract
   * @description Finish contract sign and change to next screen
   */
  const signContract = async () => {
    await writeContractSummary(step)
  }

  return {
    contractData,
    signContract,
    isLoadingData,
    isSendingData,
  }
}
