import styled from 'styled-components'
import { InputFieldV3 as Input, theme } from '@provi/provi-components'
import { Body1, Body2 } from '~/styles/global'

export const GuarantorAnalysisContentWrapper = styled.div`
  padding: 35px 40px 24px 24px;

  ${theme.breakpoints.down('sm')} {
    padding: 14px 24px;
  }
`

export const GuarantorAnalysisContentTitle = styled(Body1)`
  margin-bottom: 19px;
`

export const GuarantorAnalysisContainer = styled.div`
  max-width: 368px;
`

export const CPFInput = styled(Input)``

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  margin-top: 32px;

  div {
    max-width: 100%;

    :before {
      transition-duration: 1s;
      transition-timing-function: linear;
    }
  }

  ${theme.breakpoints.down('sm')} {
    margin-top: 36px;
    gap: 17px;
  }
`

export const GuarantorAnalysisContentText = styled(Body2)``
