import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2 } from '~/styles/global'

interface IIntroGuarantorContentText {
  isBold?: boolean
  isVariant?: boolean
}

export const IntroGuarantorContentWrapper = styled.div`
  width: 100%;
  padding: 35px 24px;
`

export const IntroGuarantorContentTitle = styled(Body1)`
  margin-bottom: 27px;
`

export const IntroGuarantorContentText = styled(Body2)<IIntroGuarantorContentText>`
  margin-bottom: 50px;
  max-width: 368px;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}

  ${({ isVariant }) =>
    isVariant &&
    css`
      margin-bottom: 0;
    `}
`

export const IntroGuarantorContentButtons = styled.div<{ isBig: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  max-width: ${({ isBig }) => (isBig ? '472px' : '368px')};

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    gap: 8px;
  }
`

export const PersonalDataContainer = styled.div``

export const IntroGuarantorContentPersonalInformation = styled.div`
  padding: 16px 0;
`
