import { ButtonV3 as Button, InputFieldV3 as Input, SelectorV3 as Selector, TextButton } from '@provi/provi-components'
import { ChangeEvent, useMemo } from 'react'
import PencilIcon from '~/components/atoms/PencilIcon'
import { masks } from '~/enums/masks'
import { Body1, Body2, Overline } from '~/styles/global'
import { IBrazilianState } from '~/types/index'
import { useWindowSize } from '~/utils/useWindowSize'
import { brazilianStates } from '../AddressScreen/states'
import { labels, placeholders } from './constants'
import { useValidateData } from './hooks'
import * as S from './styles'

const ValidateData = () => {
  const { width } = useWindowSize()

  const {
    brazilianState,
    zipCodeFormatted,
    cpfFormatted,
    phoneFormatted,
    handleSubmitReviewInfo,
    information,
    isSendingData,
    isLoadingData,
    isEditing,
    toggleEdit,
    formikForm,
    brazilianStatesSelector,
    getObjectFromAcronym,
    isInputLoading,
  } = useValidateData()

  const renderEditReview = useMemo(() => {
    return Object.entries(information).map(([label]) => {
      if (label === 'state') {
        if (width && width < 800) {
          return (
            <S.SelectorWrapper>
              <S.LabelSelector>Estado</S.LabelSelector>
              <S.SelectContent
                name="state"
                id="input-state"
                placeholder="Selecionar estado"
                value={formikForm.values.state}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  console.log(event)
                  getObjectFromAcronym(event.target.value)
                }}
              >
                <option value="" hidden>
                  Selecionar estado
                </option>
                {brazilianStates.map((state: IBrazilianState) => (
                  <option key={state.acronym} value={state.acronym}>
                    {state.label}
                  </option>
                ))}
              </S.SelectContent>
            </S.SelectorWrapper>
          )
        }

        return (
          <Selector
            id="input-state"
            label="Estado"
            name="state"
            placeholder="Selecionar estado"
            className="cy-select-state"
            isSearchable={false}
            isLoading={isLoadingData}
            value={brazilianStatesSelector}
            options={brazilianStates}
            onChange={(event: typeof brazilianStates[0]) => {
              formikForm.setFieldValue('state', event.acronym)
            }}
            onBlur={() => {
              formikForm.setFieldTouched('state')
              formikForm.validateForm()
            }}
            isValid={!formikForm.errors[label] && formikForm.errors[label] ? false : true}
            hasError={formikForm.errors[label] && formikForm.errors[label] ? true : false}
            width="100%"
          />
        )
      }

      const canInputLoad = ['zipCode', 'city', 'district', 'street'].includes(label)
      const isLoading = isInputLoading && canInputLoad

      return (
        label && (
          <Input
            key={label}
            label={labels[label]}
            name={label}
            placeholder={placeholders[label]}
            value={formikForm.values[label]}
            isValid={formikForm.touched[label] && !formikForm.errors[label]}
            hasError={formikForm.touched[label] && formikForm.errors[label]}
            errorMessage={formikForm.errors[label]}
            disabled={labels[label] === 'CPF' && true}
            onChange={(e) => {
              formikForm.setFieldValue(label, e.target.value)
            }}
            onBlur={() => {
              formikForm.setFieldTouched(label)
              formikForm.validateForm()
            }}
            width="100%"
            mask={masks[label] || ''}
            isLoading={isLoading}
          />
        )
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information, isLoadingData, formikForm, brazilianStatesSelector, getObjectFromAcronym, width, isInputLoading])

  const renderValidateData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <S.DataContent>
          <S.OverlineData>
            <Body1>Dados pessoais</Body1>
            <S.LoadingDataContent />
          </S.OverlineData>

          <S.OverlineData>
            <Body1>Endereço</Body1>
            <S.LoadingDataContent />
          </S.OverlineData>
        </S.DataContent>
      )
    }

    if (isEditing) {
      return <S.EditReview>{renderEditReview}</S.EditReview>
    }

    return (
      <S.DataContent>
        <Body1>Dados pessoais</Body1>

        <S.OverlineData>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              CPF:{' '}
            </Overline>
            {cpfFormatted}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Nome:{' '}
            </Overline>
            {information?.fullName}
          </S.InfoWrapper>
          {information?.fullSocialName && (
            <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
              <Overline data-recording-sensitive="false" data-clarity-unmask="true">
                Nome social:{' '}
              </Overline>
              {information?.fullSocialName}
            </S.InfoWrapper>
          )}
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Celular:{' '}
            </Overline>
            {phoneFormatted}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              E-mail:{' '}
            </Overline>
            {information?.email}
          </S.InfoWrapper>
        </S.OverlineData>

        <Body1>Endereço</Body1>

        <S.OverlineData>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              CEP:{' '}
            </Overline>
            {zipCodeFormatted}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Estado:{' '}
            </Overline>
            {brazilianState?.label}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Cidade:{' '}
            </Overline>
            {information?.city}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Bairro:{' '}
            </Overline>
            {information?.district}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Endereço:{' '}
            </Overline>
            {information?.street}
          </S.InfoWrapper>
          <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
            <Overline data-recording-sensitive="false" data-clarity-unmask="true">
              Número:{' '}
            </Overline>
            {information?.streetNumber}
          </S.InfoWrapper>
          {information?.complement && (
            <S.InfoWrapper data-recording-sensitive data-clarity-mask="true">
              <Overline data-recording-sensitive="false" data-clarity-unmask="true">
                Complemento:{' '}
              </Overline>
              {information?.complement}
            </S.InfoWrapper>
          )}
        </S.OverlineData>
        <TextButton
          onClick={toggleEdit}
          text={
            <>
              Editar <PencilIcon />
            </>
          }
        />
      </S.DataContent>
    )
  }, [
    isLoadingData,
    information,
    brazilianState?.label,
    cpfFormatted,
    phoneFormatted,
    zipCodeFormatted,
    renderEditReview,
    isEditing,
    toggleEdit,
  ])

  return (
    <S.ValidateDataWrapper>
      <Body2>Confira os seus dados para prosseguir com a compra.</Body2>

      {renderValidateData}

      <S.ButtonWrapper>
        <Button
          text="Continuar"
          color="primary"
          icon
          onClick={handleSubmitReviewInfo}
          disabled={!formikForm.isValid}
          isLoading={isSendingData}
        />
      </S.ButtonWrapper>
    </S.ValidateDataWrapper>
  )
}

export default ValidateData
