export const textsMethodTicket = {
  success: {
    title: 'Pronto! Seu boleto foi gerado',
    text: 'Utilize o número do código de barras abaixo para realizar o pagamento do boleto.',
  },
  failure: {
    title: 'Falha na emissão do boleto',
    text: 'Por problemas técnicos, não conseguimos emitir o boleto. Por favor, tente novamente.',
  },
}

export const textsMethodPix = {
  success: {
    title: 'Pronto! Agora é só pagar o Pix',
    text: 'Utilize o QR Code ou a chave Pix abaixo para realizar o pagamento.',
  },
  failure: {
    title: 'Falha ao gerar o seu código de pagamento',
    text: 'Por problemas técnicos, não conseguimos gerar o Pix. Por favor, tente novamente.',
  },
}

export const textsMethodCreditCard = {
  success: {
    title: 'Recebemos o seu pedido!',
    text: (
      <>
        O pagamento está sendo processado. Assim que ele for confirmado, você receberá um e-mail com os detalhes da sua compra.
        <br />
        <br /> As instruções de acesso ao curso serão enviadas pela escola diretamente para você.
      </>
    ),
  },
  failure: {
    title: 'Falha no pagamento',
    text: 'Não conseguimos processar o seu pagamento.',
  },
}

export const invalidCheckoutData = {
  title: 'Não podemos seguir com seu pedido',
  text: (
    <>
      Nenhuma forma de pagamento está disponível para você.
      <br />
      Sentimos muito não poder te ajudar.
    </>
  ),
}

export const invalidRequestTypeIsa = {
  title: 'Você ainda não terminou de pagar o seu ISA',
  text: (
    <>
      Notamos que você já possui um contrato de ISA assinado com a Principia. Para fazer um novo pedido, é necessário quitar
      todas as parcelas do financiamento que você já fez.
      <br /> <br />
      Você pode entrar em contato pelo WhatsApp se tiver alguma dúvida.
    </>
  ),
}

export const invalidCheckoutDataPendingSettlement = {
  title: 'Não podemos seguir com seu pedido',
  text: (
    <>
      Nenhuma forma de pagamento está disponível para você, porque o seu CPF parece ter pendências junto à Receita Federal.
      <br />
      Regularize a sua situação e tente comprar o seu curso outra vez.
    </>
  ),
}
