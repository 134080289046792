import { useCallback, useContext, useEffect, useState } from 'react'
import { IReadCreditInAnalysisResponse } from 'types'
import { ParamsContext } from '~/contexts/ParamsContext'
import { readCreditInAnalysis } from '~/services/api'
import { copyToClipboard } from '~/utils/copyToClipboard'
import { formatSocialText } from './formatSocialText'

interface ISocialTexts {
  htmlTexts: JSX.Element[]
  sharableText: string
  guarantorURL: string
}

export const useCreditInAnalysis = () => {
  const [creditInAnalysis, setCreditInAnalysis] = useState<IReadCreditInAnalysisResponse | null>(null)
  const [isWaitingGuarantor, setIsWaitingGuarantor] = useState<boolean | null>(null)
  const [socialTexts, setSocialTexts] = useState<ISocialTexts | null>(null)
  const { productType } = useContext(ParamsContext)

  useEffect(() => {
    const fetchCreditInAnalysis = async () => {
      const data = await readCreditInAnalysis()

      if (typeof data !== 'string') {
        setCreditInAnalysis(data.response)
        setIsWaitingGuarantor((data.response?.hasGuarantor && data.response?.data?.guarantorStatus === 'incomplete') || false)

        !!data.response?.data?.guarantorName && setSocialTexts(formatSocialText(data.response))
      }
    }

    fetchCreditInAnalysis()
  }, [])

  const handleShare = useCallback(() => {
    if (socialTexts) {
      if (!navigator.share) {
        copyToClipboard(socialTexts.sharableText)
        return
      }

      navigator.share({
        title: 'Principia - Avalista de Crédito Estudantil',
        text: socialTexts.sharableText,
      })
    }
  }, [socialTexts])

  const headerText = {
    isWaitingGuarantor: 'Documentos da pessoa avalista',
    isNotWaitingGuarantor: 'Documentos enviados',
    wasInReview: 'Informações revisadas',
  }

  const textBodyByProductType = {
    ISA: {
      assessmentAnswered: 'Dentro dos próximos dias, a escola irá te retornar com uma posição sobre o seu processo seletivo.',
      wasInReview:
        'Vamos dar uma olhada em tudo outra vez e se tudo estiver certinho, em breve, a escola irá te retornar com uma posição sobre o seu processo seletivo.',
    },
    ProviPay: {
      wasInReview:
        'Vamos dar uma olhada em tudo outra vez e, em breve, entraremos em contato por e-mail para te informar sobre os próximos passos.',
    },
  }

  return {
    creditInAnalysis,
    isWaitingGuarantor,
    socialTexts,
    handleShare,
    headerText,
    productType,
    textBodyByProductType,
  }
}
