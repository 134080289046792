import { ButtonV3 as Button } from '@provi/provi-components'
import { useMemo } from 'react'
import { SummaryReviewText, Svg } from '~/components/atoms'
import { Link } from '~/styles/global'
import { IPaymentFlow } from '~/types/index'
import { formatInstallmentNumber, formatMonthlyInterestText, parseDiscount, parsePrice } from '~/utils/index'
import { useSignatureSummaryContent } from './hooks'
import * as S from './style'

interface IDateCells {
  index: number
  data: IPaymentFlow
}

const DateCells = ({ index, data }: IDateCells) => {
  const { paymentValue, paymentDate, isUpfront } = data || {}

  if (!paymentValue || !paymentDate) {
    return null
  }

  return (
    <>
      <S.DateCellsText justifyContent="flex-start">{formatInstallmentNumber(index, isUpfront)}</S.DateCellsText>
      <S.DateCellsText justifyContent="center">{parsePrice(paymentValue)}</S.DateCellsText>
      <S.DateCellsText justifyContent="flex-end">{paymentDate}</S.DateCellsText>
    </>
  )
}

export const SignatureSummaryContent = () => {
  const {
    financingSummaryData,
    contractSummaryData,
    ccbDraft,
    handleToggle,
    summaryInstallmentsToApply,
    handleNextScreen,
    isLoadingData,
    isSendingData,
    shouldShowMore,
    showTaxesOnCheckout,
  } = useSignatureSummaryContent()

  const renderFinancingSummaryData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return Array.from({ length: 6 }).map((_, index) => <S.SummaryRowLoading key={index} />)
    }

    return (
      <>
        <S.SummaryReviewWrapper>
          <S.SummaryReviewTitle>{financingSummaryData?.partnerName}</S.SummaryReviewTitle>
          <S.SummaryReviewContainer>
            {financingSummaryData?.courseNames.map((course, index) => (
              <S.SummaryReviewContent key={index}>
                <S.SummaryReviewTitle>{course.courseName}</S.SummaryReviewTitle>
                <S.SummaryReviewTitle>{course.className}</S.SummaryReviewTitle>
              </S.SummaryReviewContent>
            ))}
          </S.SummaryReviewContainer>

          <SummaryReviewText
            title={`${summaryInstallmentsToApply}`}
            value={parsePrice(financingSummaryData?.PMT || 0)}
            largerFont
          />
          <SummaryReviewText title="Entrada" value={parsePrice(financingSummaryData?.upfrontAmount || 0)} largerFont />
          <SummaryReviewText
            hidden={!showTaxesOnCheckout}
            title="Total a pagar"
            value={parsePrice(financingSummaryData?.totalPriceToPay || 0)}
            largerFont
            colorVariant
          />
          <hr />

          <SummaryReviewText title="IOF total" value={parsePrice(financingSummaryData?.IOF || 0)} largerFont />
          <SummaryReviewText
            title="Taxa de juros"
            value={formatMonthlyInterestText((financingSummaryData && financingSummaryData?.monthlyInterest * 100) || 0)}
            largerFont
          />

          <SummaryReviewText
            title="Custo efetivo total (CET)"
            value={`${parseDiscount((financingSummaryData && financingSummaryData?.CETAnnual * 100) || 0, 2)} a.a.`}
            largerFont
          />

          <hr />
          <S.DateCellsWrapper shouldShowMore={shouldShowMore} cellsCount={financingSummaryData?.paymentFlow?.length || 2}>
            {financingSummaryData?.paymentFlow?.map((paymentData, index) => {
              return <DateCells key={index} index={index} data={paymentData} />
            })}
          </S.DateCellsWrapper>

          {!showTaxesOnCheckout && shouldShowMore ? (
            <div className="py-4">
              <SummaryReviewText
                title="Total a pagar"
                value={parsePrice(financingSummaryData?.totalPriceToPay || 0)}
                largerFont
              />
            </div>
          ) : null}
          {financingSummaryData?.paymentFlow?.length > 4 && (
            <S.SeeMore onClick={handleToggle}>{shouldShowMore ? 'Ver menos' : 'Ver todas as parcelas'}</S.SeeMore>
          )}
        </S.SummaryReviewWrapper>
      </>
    )
  }, [financingSummaryData, handleToggle, summaryInstallmentsToApply, shouldShowMore, isLoadingData, showTaxesOnCheckout])

  const renderContractSummaryData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return Array.from({ length: 6 }).map((_, index) => <S.SummaryRowLoading key={index} />)
    }

    return (
      <S.ContractContainer>
        <label className="label-header">Informações importantes</label>
        {contractSummaryData?.map((element) => (
          <S.ContractItemContainer key={element.title}>
            <label className="label-title">{element.title}</label>
            <div dangerouslySetInnerHTML={{ __html: `<label>` + element.text + `</label>` }} />
          </S.ContractItemContainer>
        ))}

        <S.ContractItemContainer>
          <label>
            Esse foi um resumo com pontos importantes do contrato com a Principia. Você deve ler o documento completo antes de
            contratar.{' '}
            <Link target="_blank" href={ccbDraft || 'https://provi.com.br/contrato'} rel="noopener noreferrer">
              Clique aqui para visualizar todas as cláusulas do contrato.
            </Link>
          </label>
          <br />
          <br />

          <label>Após assinatura, você poderá acessar o contrato completo no seu Painel do Aluno.</label>
        </S.ContractItemContainer>
      </S.ContractContainer>
    )
  }, [contractSummaryData, isLoadingData, ccbDraft])

  return (
    <S.FinancingSummaryWrapper>
      <S.FinancingSummaryTitle>Resumo do financiamento</S.FinancingSummaryTitle>

      <div className="flex flex-col gap-4 pb-[220px] md:pb-0">
        <div className="max-h-HBase overflow-y-scroll flex flex-col gap-4">
          {renderFinancingSummaryData}
          {renderContractSummaryData}
        </div>
      </div>

      <div className="flex flex-col gap-4 fixed bottom-0 left-0 bg-white/95 p-4 md:relative md:p-0">
        <S.ContractInformationContainer>
          <Svg src="/assets/svg/info.svg" alt="Icone I sinalizando informações" width={50} height={50} />
          <label>
            Clicando no botão abaixo, você concorda que leu e está de acordo com o contrato. A assinatura eletrônica vale como a
            de próprio punho.
          </label>
        </S.ContractInformationContainer>

        <Button text="Assinar contrato" icon onClick={handleNextScreen} isLoading={isSendingData} mobileWidth="100%" />
      </div>
    </S.FinancingSummaryWrapper>
  )
}
