import * as S from './style'

interface FinancingTextBoxProps {
  title?: string
  text: string
}

const FinancingTextBox = ({ title, text }: FinancingTextBoxProps) => {
  return (
    <S.FinancingSuccessDataTextBox>
      {title && (
        <S.FinancingSuccessDataText isTitle={true} as="h2">
          {title}
        </S.FinancingSuccessDataText>
      )}
      <S.FinancingSuccessDataText>{text}</S.FinancingSuccessDataText>
    </S.FinancingSuccessDataTextBox>
  )
}

export default FinancingTextBox
