import { useMemo } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'
import { RowSummaryTicketSvg } from '~/components/atoms'
import { CartTotalPrice, SummaryTicket } from '~/components/molecules'
import { usePixForm } from './hooks'
import * as S from './style'

interface IPixForm {
  hideSubmit?: boolean
}

const PixForm = ({ hideSubmit }: IPixForm) => {
  const { onSubmitButton, cartData, isSendingData, chosenPaymentMethod } = usePixForm()

  const renderItems = useMemo(() => {
    return (
      <S.PaymentSummaryContent marginVariant={cartData?.courses.length > 1}>
        {cartData?.courses?.map((elem) => (
          <SummaryTicket
            key={elem?.CourseClassId}
            classNameByPartner={elem?.className}
            courseName={elem?.courseName}
            price={elem?.price}
          />
        ))}
        <S.SummaryTotalPrice>
          <CartTotalPrice
            discountPercentage={cartData?.discountPercentage}
            totalPrice={cartData?.totalPrice}
            totalPriceToPay={cartData?.totalPriceToPay}
          />
        </S.SummaryTotalPrice>
        <RowSummaryTicketSvg />
      </S.PaymentSummaryContent>
    )
  }, [cartData])

  return (
    <S.PaymentMethodTicketWrapper>
      {renderItems}
      <S.PaymentMethodTicketText>
        Na próxima etapa você receberá um QRCode e um código para realizar o pagamento com Pix.
        <br />
        Caso o pagamento não seja realizado até a data de vencimento, a compra será cancelada.
      </S.PaymentMethodTicketText>

      {!hideSubmit && (
        <S.ButtonWrapper shouldShowOnMobile={!!chosenPaymentMethod?.length}>
          <Button
            text="Continuar"
            icon={true}
            mobileWidth="100%"
            disabled={!chosenPaymentMethod?.length}
            onClick={onSubmitButton}
            isLoading={isSendingData}
          />
        </S.ButtonWrapper>
      )}
    </S.PaymentMethodTicketWrapper>
  )
}

export default PixForm
