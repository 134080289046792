import { event } from '.'
import { getCRID } from '../getCRID'

export const gtagGenerateLead = () => {
  const crid = getCRID()

  event({
    action: 'generate_lead',
    eventParams: {
      creditRequestId: crid,
    },
  })
}
