import { toast } from 'react-toastify'
import { uploadToFirebase } from '~/utils/uploadToFirebase'

export const uploadImage = (file: string, partnerId: string) => {
  try {
    return uploadToFirebase(file, partnerId)
  } catch (err) {
    toast.error(err.message, {
      toastId: 'uploadImage',
    })
  }

  return null
}
