import GuarantorAnalysisContent from '~/components/organisms/GuarantorAnalysisContent'
import * as S from './style'

const GuarantorAnalysis = () => (
  <S.GuarantorAnalysisWrapper>
    <GuarantorAnalysisContent />
  </S.GuarantorAnalysisWrapper>
)

export default GuarantorAnalysis
