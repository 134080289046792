import { useMemo } from 'react'
import { PaymentMethodCards, CreditCardForm, CourseFinancingForm, BoletoForm } from '~/components/organisms'
import { InformationTextWithTooltip } from '~/components/molecules'
import { usePaymentSelectionScreen } from './hooks'
import { InstabilityModal } from '~/components/atoms/InstabilityModal'
import * as S from './style'
import PixForm from '~/components/organisms/PixForm'

export const PaymentSelectionScreen = () => {
  const {
    chosenPaymentMethod,
    imTheCardHolder,
    isInCourseFinancingReviewScreen,
    paymentInformationData,
    setImTheCardHolder,
    setTemporaryCreditCardData,
    temporaryCreditCardData,
  } = usePaymentSelectionScreen()

  const hasInstability = process.env.NEXT_PUBLIC_HAS_INSTABILITY === 'true' ? true : false

  const renderChosenPaymentMethodForm = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'CreditCard':
        return (
          <CreditCardForm
            temporaryCreditCardData={temporaryCreditCardData}
            setTemporaryCreditCardData={setTemporaryCreditCardData}
            imTheCardHolder={imTheCardHolder}
            setImTheCardHolder={setImTheCardHolder}
          />
        )
      case 'Boleto':
        return <BoletoForm />
      case 'CourseFinancing':
        return <CourseFinancingForm />
      case 'Pix':
        return <PixForm />
      default:
        return <p>{chosenPaymentMethod && `Tela de ${chosenPaymentMethod}`}</p>
    }
  }, [chosenPaymentMethod, temporaryCreditCardData, setTemporaryCreditCardData, imTheCardHolder, setImTheCardHolder])

  const shouldShowPaymentMethods = useMemo(() => {
    if (chosenPaymentMethod === 'CourseFinancing' && isInCourseFinancingReviewScreen) {
      return
    }

    return <PaymentMethodCards hasInstability={hasInstability} />
  }, [chosenPaymentMethod, isInCourseFinancingReviewScreen, hasInstability])

  return (
    <S.WrapperForm hasChosenPaymentMethod={!!chosenPaymentMethod?.length || false}>
      <InstabilityModal hasInstability={hasInstability} />
      {!chosenPaymentMethod && <S.TitleForm as="h1">Como você prefere pagar?</S.TitleForm>}

      {shouldShowPaymentMethods}

      {chosenPaymentMethod && renderChosenPaymentMethodForm}

      <S.BottomInformation>
        {!chosenPaymentMethod && !!paymentInformationData?.response?.reason?.length && (
          <InformationTextWithTooltip
            text="Por que o financiamento não está disponível?"
            tooltipText={paymentInformationData?.response.reason}
          />
        )}
      </S.BottomInformation>
    </S.WrapperForm>
  )
}
