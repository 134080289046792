import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readCreditDenied, writeCreditDenied } from '~/services/api'

export const useCreditDeniedScreen = () => {
  const { isLoadingData, isSendingData, setChosenPaymentMethod } = useContext(CheckoutContext)
  const [canSelectNewPaymentMethod, setCanSelectNewPaymentMethod] = useState(false)

  useEffect(() => {
    ;(async () => {
      const data = await readCreditDenied()

      if (typeof data !== 'string') {
        setCanSelectNewPaymentMethod(data.response.canSelectNewPaymentMethod)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNextScreen = useCallback(async () => {
    setChosenPaymentMethod(null)

    await writeCreditDenied()
  }, [setChosenPaymentMethod])

  return {
    isLoadingData,
    isSendingData,
    handleNextScreen,
    canSelectNewPaymentMethod,
  }
}
