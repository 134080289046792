import { ButtonV3, PortalModal } from '@provi/provi-components'
import { useContext, useEffect } from 'react'
import { CoursesContext } from '~/contexts/CoursesContext'
import * as S from './styles'

export const InstabilityModal = ({ hasInstability }) => {
  const { modalRef, openModal, closeModal } = useContext(CoursesContext)

  useEffect(() => {
    hasInstability && openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PortalModal ref={modalRef}>
        <S.InstabilityModalContent>
          <p>
            Por conta de um problema técnico, os pagamentos com cartão de crédito e boleto à vista não estão disponíveis no
            momento. Estamos trabalhando para restabelecer esses serviços o quanto antes. Obrigada pela compreensão.
          </p>
          <br />
          <br />
          <ButtonV3 icon={false} text="Entendi" onClick={closeModal} />
        </S.InstabilityModalContent>
      </PortalModal>
    </>
  )
}
