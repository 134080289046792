import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { Caption } from '~/styles/global'

interface ISingleQuestionContent {
  hasExtraInfo?: boolean
}

export const SingleQuestionContent = styled.div<ISingleQuestionContent>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 368px;
  position: relative;

  .select-menu {
    div:first-child {
      z-index: 0;
    }
  }

  .select-label {
    padding-right: ${({ hasExtraInfo }) => (hasExtraInfo ? '16px' : '0')};
  }

  div {
    label:not([data-testId='test-label']) {
      p {
        padding-right: ${({ hasExtraInfo }) => (hasExtraInfo ? '16px' : '0')};
      }

      div {
        min-height: 48px;
        height: auto;

        textarea {
          resize: none;
          min-height: 80px;
          height: auto;
        }
      }
    }
  }
`

export const RadioTitle = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SingleQuestionDescription = styled(Caption)``

export const SingleQuestionTip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 0px;

  width: 16px;
  height: 16px;
  padding: 8px;
  border-radius: 50%;

  background: ${theme.colors.bluePrimary};
  color: ${theme.colors.white100};
  cursor: pointer;
`

export const SingleQuestionTipTitle = styled.h3`
  padding-bottom: 4px;
`

export const SingleQuestionTipText = styled.p`
  padding-top: 8px;
`
