// Y8.1BA screen
export const appointSecondGuarantorWithPaymentForm = {
  title: 'Precisamos fazer alguns ajustes',
  text: (
    <>
      <p>Não conseguimos aprovar seu financiamento com as condições que você escolheu.</p>
      <p>Para continuar, você pode indicar uma pessoa avalista ou escolher uma forma de pagamento diferente.</p>
    </>
  ),
  tip: 'Avalista é uma pessoa que poderemos acionar e responsabilizar caso você deixe de pagar o seu financiamento.',
  primaryButtonText: 'Indicar avalista',
  secondaryButtonText: 'Pagar de outra forma',
  primaryButtonShouldShowIcon: false,
  isBanned: false,
}

// Y8.1BB screen
export const appointSecondGuarantorWithoutPaymentForm = {
  title: 'Precisamos fazer alguns ajustes',
  text: (
    <>
      <p>Não conseguimos aprovar seu financiamento com as condições que você escolheu.</p>
      <p>Para continuar, você precisa indicar uma pessoa avalista</p>
    </>
  ),
  tip: 'Avalista é uma pessoa que poderemos acionar e responsabilizar caso você deixe de pagar o seu financiamento.',
  primaryButtonText: 'Indicar avalista',
  primaryButtonShouldShowIcon: true,
  isBanned: false,
}

// Y8.3BA screen
export const appointNewGuarantorWithPaymentForm = {
  title: 'Ainda não foi dessa vez',
  text: <p>Para continuar, você pode indicar outra pessoa avalista ou escolher uma forma de pagamento diferente.</p>,
  primaryButtonText: 'Indicar outra pessoa',
  secondaryButtonText: 'Pagar de outra forma',
  primaryButtonShouldShowIcon: false,
  isBanned: false,
}

// Y8.3BB screen
export const appointNewGuarantorWithoutPaymentForm = {
  title: 'Ainda não foi dessa vez',
  text: (
    <>
      <p>Para continuar, você precisa indicar outra pessoa avalista.</p>
    </>
  ),
  primaryButtonText: 'Indicar outra pessoa',
  primaryButtonShouldShowIcon: true,
  isBanned: false,
}

// Y8.6BA screen
export const changePaymentForm = {
  title: 'Não conseguimos aprovar seu financiamento',
  text: (
    <>
      <p>Você já indicou 2 pessoas. Para continuar, você pode escolher outra forma de pagamento.</p>
    </>
  ),
  primaryButtonText: 'Pagar de outra forma',
  primaryButtonShouldShowIcon: false,
  isBanned: true,
}
