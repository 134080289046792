import styled from 'styled-components'

export const FormScreenNContainer = styled.div`
  padding: 24px;
`

export const FormScreenNContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 48px;
`
