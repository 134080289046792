import { ButtonV3 as Button } from '@provi/provi-components'
import { LoadingInput } from '~/components/molecules'
import { useIntroIsa } from './hooks'
import * as S from './styles'

export const IntroISA = () => {
  const { proceed, isSendingData, infosData, isLoadingData } = useIntroIsa()

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return (
      <S.IntroIsaWrapper>
        <S.IntroIsaContentLoading>
          <LoadingInput hasLabel={false} inputWidth="288px" />
          <LoadingInput hasLabel={false} />
        </S.IntroIsaContentLoading>

        <LoadingInput hasLabel={false} inputWidth="180px" />
      </S.IntroIsaWrapper>
    )
  }

  return (
    <S.IntroIsaWrapper>
      <S.IntroIsaContent>
        <S.IntroIsaTitle>{infosData?.title}</S.IntroIsaTitle>
        {infosData?.text?.map((infoText, index) => (
          <S.IntroIsaSubTitle key={index}>{infoText}</S.IntroIsaSubTitle>
        ))}
      </S.IntroIsaContent>

      <Button icon text="Começar" onClick={proceed} isLoading={isSendingData} mobileWidth="100%" />
    </S.IntroIsaWrapper>
  )
}
