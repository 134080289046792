import { useRef, useCallback } from 'react'
import * as S from './styles'
import { ButtonV3 as Button, TextButton, PortalModal } from '@provi/provi-components'
import dynamic from 'next/dynamic'
import { useUnicoSelfie } from './hooks'
import { LoadingRing } from '~/components/molecules'
import { BackButton, Svg } from '~/components/atoms'
import Webcam from 'react-webcam'
import { ModalContainer } from '../../ModalContainer'
import { LockIcon } from '~/components/atoms/LockIcon'

const WebcamDeniedModal = ({ modalRef, retryCallback }) => {
  return (
    <PortalModal ref={modalRef} borderRadius={'8px'} isReadOnly={true}>
      <ModalContainer>
        <S.DeniedWebcamContainer>
          <S.DeniedWebcamTitle>Sua câmera não está habilidada para uso.</S.DeniedWebcamTitle>
          <S.DeniedWebcamText as="span">
            Para continuar com a sua solicitação, você precisa habilitar a câmera do seu dispositivo.
            <S.DeniedWebcamSpacing />
            <S.DeniedWebcamOrderedList>
              <S.DeniedWebcamListItem>
                Clique no ícone de cadeado
                <sup>
                  <LockIcon />
                </sup>{' '}
                na barra de endereço do seu navegador.
              </S.DeniedWebcamListItem>
              <S.DeniedWebcamListItem>Ative a permissão para acessar a câmera.</S.DeniedWebcamListItem>
              <S.DeniedWebcamListItem>
                <S.DeniedWebcamButton
                  as="button"
                  onClick={() => {
                    modalRef?.current?.closeModalFn()
                    retryCallback()
                  }}
                >
                  Clique aqui
                </S.DeniedWebcamButton>{' '}
                para recarregar a página.
              </S.DeniedWebcamListItem>
            </S.DeniedWebcamOrderedList>
          </S.DeniedWebcamText>
        </S.DeniedWebcamContainer>
      </ModalContainer>
    </PortalModal>
  )
}

const UnicoFailedModal = ({ modalRef, retryCallback, modalMessage }) => {
  return (
    <PortalModal ref={modalRef} borderRadius={'8px'} isReadOnly={true}>
      <ModalContainer>
        <S.DeniedWebcamContainer>
          <S.DeniedWebcamTitle>Houve um problema com a sua foto</S.DeniedWebcamTitle>
          <S.DeniedWebcamText as="span">{modalMessage || 'Precisamos que você tire uma nova selfie'}</S.DeniedWebcamText>
          <Button
            text="Tirar outra selfie"
            color="primary"
            onClick={() => {
              modalRef?.current?.closeModalFn()
              retryCallback()
            }}
            width="100%"
            mobileWidth="100%"
          />
        </S.DeniedWebcamContainer>
      </ModalContainer>
    </PortalModal>
  )
}

const UnicoSelfieScreen = () => {
  const {
    isWebcamEnabled,
    picture,
    uploadFile,
    isLoading,
    shootAgain,
    setPicture,
    videoConstraints,
    isSendingData,
    modalRef,
    unicoModalRef,
    initUnico,
    modalMessage,
  } = useUnicoSelfie()
  const webcamRef = useRef<Webcam>(null)

  const capture = useCallback(() => {
    const image = webcamRef.current?.getScreenshot()
    setPicture(image || '')
  }, [webcamRef, setPicture])

  if (isLoading) {
    return <LoadingRing />
  }

  if (!picture) {
    return (
      <>
        <S.BackButtonWebcam>
          <BackButton step={'preSelfie'} />
        </S.BackButtonWebcam>

        {isWebcamEnabled ? (
          <S.Container>
            <WebcamDeniedModal modalRef={modalRef} retryCallback={initUnico} />
            <UnicoFailedModal modalRef={unicoModalRef} retryCallback={initUnico} modalMessage={modalMessage} />
            <S.WebcamComponent
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={webcamRef}
              videoConstraints={videoConstraints}
              height={999}
              screenshotQuality={1}
            ></S.WebcamComponent>
            <S.WebcamWrapper>
              <S.FaceCircle />
              <button type="button" onClick={capture} id="camera--trigger">
                <Svg src={'/assets/svg/camera.svg'} alt={'Icone de camera'} width={24} height={21} />
              </button>
            </S.WebcamWrapper>
          </S.Container>
        ) : (
          <S.Container>
            <S.WebcamWrapper>Carregando sua câmera...</S.WebcamWrapper>
            <WebcamDeniedModal modalRef={modalRef} retryCallback={initUnico} />
            <UnicoFailedModal modalRef={unicoModalRef} retryCallback={initUnico} modalMessage={modalMessage} />
            <div id="box-camera" />
          </S.Container>
        )}
      </>
    )
  }

  return (
    <S.Container>
      <S.Container>
        <S.InnerContainerWrapper>
          <S.InnerImageContainer data-recording-disable>
            <S.Image src={picture || ' '} />
          </S.InnerImageContainer>
        </S.InnerContainerWrapper>

        <S.Label>Pronto! Você pode continuar ou tirar outra foto.</S.Label>
      </S.Container>

      <S.ButtonWrapper>
        <TextButton text="Tirar outra" onClick={() => shootAgain()} />
        <Button
          text="Continuar"
          color="primary"
          disabled={picture === ''}
          icon
          onClick={uploadFile}
          isLoading={isSendingData}
          mobileWidth="100%"
        />
      </S.ButtonWrapper>
    </S.Container>
  )
}

export default dynamic(() => Promise.resolve(UnicoSelfieScreen), { ssr: false })
