import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const LoadingIconWrapper = styled.div<{ iconWidth: string }>`
  border: ${({ iconWidth }) => `${parseInt(iconWidth.replace('px', '')) / 12}px solid ${theme.colors.white100}`};
  border-top: ${({ iconWidth }) => `${parseInt(iconWidth.replace('px', '')) / 12}px solid ${theme.colors.bluePrimary}`};
  border-radius: 50%;
  width: ${({ iconWidth }) => iconWidth};
  height: ${({ iconWidth }) => iconWidth};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
