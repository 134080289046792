import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2 } from '~/styles/global'

export const IntroIsaWrapper = styled.div`
  min-height: 496px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IntroIsaContent = styled.div`
  max-width: 368px;
`

export const IntroIsaTitle = styled(Body1)`
  margin-bottom: 30px;
`

export const IntroIsaSubTitle = styled(Body2)`
  margin-bottom: 10px;
`

export const IntroIsaContentLoading = styled.div`
  max-width: 368px;

  div:nth-child(2) {
    margin-top: 20px;

    div {
      height: 100px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    div {
      width: 100%;
    }
  }
`
