import { IGenerateOTP } from 'types'
import { ValidateVerificationForm } from '~/components/index'

interface IVerification {
  verificationType: IGenerateOTP['validationType']
}

const ValidateVerificationScreen = ({ verificationType }: IVerification) => (
  <ValidateVerificationForm verificationType={verificationType} />
)

export default ValidateVerificationScreen
