import { useContext, useEffect, useMemo, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ITemporaryCreditCardData } from '~/types/index'

export const usePaymentSelectionScreen = () => {
  const { chosenPaymentMethod, paymentInformationData, isInCourseFinancingReviewScreen, setChosenPaymentMethod } =
    useContext(CheckoutContext)
  const [temporaryCreditCardData, setTemporaryCreditCardData] = useState<ITemporaryCreditCardData | null>(null)
  const [imTheCardHolder, setImTheCardHolder] = useState(true)

  const currentPaymentMethod = useMemo(() => {
    if (!paymentInformationData?.response?.paymentMethods?.length || paymentInformationData?.response?.reason?.length) {
      return null
    }

    return paymentInformationData?.response?.paymentMethods?.includes('CourseFinancing') ? 'CourseFinancing' : 'CreditCard'
  }, [paymentInformationData?.response?.paymentMethods, paymentInformationData?.response?.reason?.length])

  useEffect(() => {
    !chosenPaymentMethod && setChosenPaymentMethod(currentPaymentMethod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentMethod])

  return {
    chosenPaymentMethod,
    paymentInformationData,
    isInCourseFinancingReviewScreen,
    temporaryCreditCardData,
    setTemporaryCreditCardData,
    imTheCardHolder,
    setImTheCardHolder,
  }
}
