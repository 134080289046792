import { useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { LocalStorageKeys } from '~/enums/localStorageKeys'
import {
  invalidCheckoutData,
  invalidCheckoutDataPendingSettlement,
  invalidRequestTypeIsa,
} from '~/enums/textsPaymentMethodTicket'

export const useInvalidCheckout = () => {
  const { setIsLoadingData, alreadyHasActiveProductType } = useContext(CheckoutContext)

  const shouldUsePendingSettlementText = sessionStorage.getItem(LocalStorageKeys.isPendingSettlement) === 'true'

  const shouldUseAlreadyHasActiveProductTypeText = alreadyHasActiveProductType ? invalidRequestTypeIsa : invalidCheckoutData

  const invalidCheckoutText = shouldUsePendingSettlementText
    ? invalidCheckoutDataPendingSettlement
    : shouldUseAlreadyHasActiveProductTypeText

  useEffect(() => {
    setIsLoadingData(false)
  }, [setIsLoadingData])

  return { shouldUsePendingSettlementText, invalidCheckoutText, alreadyHasActiveProductType }
}
