export const LoadingFinanceSimulationContent = ({ icon, text }) => {
  return (
    <div className="flex items-center justify-center w-full h-full text-center">
      <div className="max-w-MXBase flex flex-col items-center justify-center h-HBase">
        {icon}
        <h1 className="mt-4">{text}</h1>
      </div>
    </div>
  )
}
