import { ButtonV3 as Button } from '@provi/provi-components'
import { Svg } from '~/components/atoms'
import { LoadingRing, PencilIcon } from '~/components/molecules'
import { useContractSummary } from './hooks'
import * as S from './style'

const ContractSummary = () => {
  const { contractData, signContract, isLoadingData, isSendingData } = useContractSummary()

  return (
    <>
      {isLoadingData ? (
        <LoadingRing />
      ) : (
        <S.ContractContainer>
          <label className="label-header">Informações importantes</label>
          {contractData?.response?.items.map((element) => (
            <S.ContractItemContainer key={element.title}>
              <label className="label-title">{element.title}</label>
              <div dangerouslySetInnerHTML={{ __html: `<label>` + element.text + `</label>` }} />
            </S.ContractItemContainer>
          ))}

          <S.ContractItemContainer>
            <label>
              Esse foi um resumo com pontos importantes do contrato com a Principia. Recomendamos que você leia o{' '}
              <a target="_blank" href="https://provi.com.br/contrato" rel="noopener noreferrer">
                documento completo
              </a>{' '}
              antes de contratar.
            </label>
          </S.ContractItemContainer>

          <S.ContractInformationContainer>
            <Svg src="/assets/svg/info.svg" alt="Icone I sinalizando informações" width={50} height={50} />
            <label>Você também receberá uma cópia por e-mail. A confirmação vale como uma assinatura de próprio punho.</label>
          </S.ContractInformationContainer>

          <S.ButtonContainer>
            <Button
              as="button"
              text="Assinar contrato"
              onClick={signContract}
              buttonProps={{ endIcon: <PencilIcon /> }}
              isLoading={isSendingData}
            />
          </S.ButtonContainer>
        </S.ContractContainer>
      )}
    </>
  )
}

export default ContractSummary
