import jwt from 'jsonwebtoken'
import { IShotMethod } from '~/types/index'

declare namespace IUnicoJWTV3 {
  export interface Lib {
    version: string
    date: number
  }

  export interface Device {
    ua: string
    model?: any
    os: string
    platform: string
    api: string
  }

  export interface IInfo {
    status: boolean
  }

  export interface Connections {
    effectiveType: string
    rtt?: any
    type: string
    downlink?: any
  }

  export interface Info {
    lib: Lib
    device: Device
    i_info: IInfo
    url_domain: string
    connections: Connections
  }

  export interface RootObject {
    faceScan: string
    lowQualityAuditTrailImage: string
    auth: string
    image: string
    info: Info
    iat: number
    iss: string
    exp: number
  }
}

declare namespace IUnicoJWTV2 {
  export interface Lib {
    version: string
    date: number
  }

  export interface Device {
    ua: string
    model?: any
    os: string
    platform: string
    api: string
  }

  export interface Capture {
    type: string
    type_exc: number
    type_init: number
  }

  export interface Silhouette {
    w: number
    h: number
  }

  export interface Info2 {
    dev: boolean
    ied: boolean
    snai: any[]
    sai: any[]
    asl: string[]
    hbb: boolean
    hwb: boolean
  }

  export interface IInfo {
    status: boolean
    info: Info2
  }

  export interface Video {
    w: number
    h: number
  }

  export interface Connections {
    effectiveType: string
    rtt?: any
    type: string
    downlink?: any
  }

  export interface Timers {
    tt: number
    ta: number
    tw: number
    tc: number
    tm: number
    tp: number
  }

  export interface Info {
    lib: Lib
    device: Device
    url_domain: string
    capture: Capture
    blinks: number
    silhouette: Silhouette
    i_info: IInfo
    video: Video
    connections: Connections
    asp_rd: number
    timers: Timers
  }

  export interface RootObject {
    info: Info
    image: string
    iat: number
    iss: string
    exp: number
  }
}

export const getUnicoShotMethodFromJWT = (encryptedLiveness?: string): IShotMethod => {
  if (encryptedLiveness) {
    const decoded = jwt.decode(encryptedLiveness) as IUnicoJWTV3.RootObject & IUnicoJWTV2.RootObject

    if (decoded?.info?.capture) {
      if (decoded.info?.capture.type_exc === 1) {
        return 'unicoSimpleCamera'
      }

      return 'unicoSmartCamera'
    }

    return 'unicoLiveness'
  }

  return 'localWebFrame'
}
