import { useCallback, useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readEndBasicInfo, writeEndBasicInfo } from '~/services/api'

export const useEndBasicInfoScreen = () => {
  const { isLoadingData, isSendingData, step } = useContext(CheckoutContext)

  useEffect(() => {
    ;(async () => {
      await readEndBasicInfo(step)
    })()
  }, [step])

  const handleNextScreen = useCallback(async () => {
    await writeEndBasicInfo(step)
  }, [step])

  return {
    isLoadingData,
    isSendingData,
    handleNextScreen,
  }
}
