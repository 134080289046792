import { ButtonV3 as Button, InputFieldV3 as Input } from '@provi/provi-components'
import { ChangeEvent } from 'react'
import { InputLabelWithDescription } from '~/components/atoms/InputLabelWithDescription'
import LoadingInput from '~/components/molecules/LoadingInput'
import { useCouponCode } from './hooks'
import * as S from './styles'

export const CouponCode = () => {
  const { formikForm, handleEnterKey, isSendingData, isLoadingData } = useCouponCode()

  const { values, setFieldValue, errors, touched, handleSubmit } = formikForm

  return (
    <S.CouponCodeWrapper>
      <S.CouponCodeContainer>
        {isLoadingData && process.env.NODE_ENV !== 'test' ? (
          <LoadingInput />
        ) : (
          <>
            <Input
              label={
                <InputLabelWithDescription
                  label="Possui algum cupom de desconto? Se sim, digite abaixo"
                  description="Caso não tenha, avance sem preencher"
                />
              }
              autoFocus
              placeholder="Digite um cupom"
              width="368px"
              value={values?.coupon || ''}
              name="coupon"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('coupon', e.target.value)}
              onBlur={() => {
                formikForm.setFieldTouched('coupon')
                formikForm.validateForm()
              }}
              isValid={!errors?.coupon && touched?.coupon}
              hasError={errors?.coupon && touched?.coupon}
              errorMessage={errors?.coupon}
              onKeyDown={handleEnterKey}
            />
            <S.CouponCodeButtonContainer>
              <Button type="submit" text="Continuar" mobileWidth="100%" icon onClick={handleSubmit} isLoading={isSendingData} />
            </S.CouponCodeButtonContainer>
          </>
        )}
      </S.CouponCodeContainer>
    </S.CouponCodeWrapper>
  )
}
