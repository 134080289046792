import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, loadingCSS } from '~/styles/global'

export const ButtonWrapper = styled.div``

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;
`

export const WrapperInput = styled.form`
  margin-top: 22px;

  > div {
    margin-bottom: 24px;

    label[label='CEP'] {
      span {
        white-space: nowrap;

        ${theme.breakpoints.down('md')} {
          white-space: unset;
        }
      }
    }

    :nth-last-child(2) {
      margin-bottom: 31px;
    }
  }

  .cy-select-state {
    > div {
      z-index: 0;
    }
  }
`

export const InputContent = styled.div`
  display: flex;
  width: 368px;
  justify-content: space-between;
  gap: 16px;

  ${theme.breakpoints.down('md')} {
    width: 100%;

    > input:nth-child(1) {
      margin-left: 16px;
    }
  }
`

export const WrapperInputLoading = styled(WrapperInput)`
  max-width: 368px;
  gap: 24px;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
    max-width: 100%;

    > div {
      :nth-child(4) {
        width: 100%;
      }
    }
  }
`

export const AddressLoadingButton = styled.div`
  height: 24px;
  max-width: 184px;
  margin-top: 16px;

  ${loadingCSS}
`

export const InputContentLoading = styled(InputContent)`
  div:first-child {
    margin-right: 24px;
  }
`

export const SelectorWrapper = styled.div``

export const SelectContent = styled.select`
  width: 100%;
  height: 48px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 3px;
  padding: 8px;
  font-family: 'Montserrat', sans-serif;
  background: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  :focus,
  :focus-visible,
  :focus-within {
    outline: 2px auto ${theme.colors.bluePrimary};
  }

  /* Change default <select /> arrow */
  background: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 20 20' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path></svg>")
    no-repeat;
  background-position: calc(100% - 8px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const LabelSelector = styled(Body2)`
  font-weight: 600;
  margin-bottom: 8px;
`
