import { useEffect, useState } from 'react'
import { readInvalidPath } from '~/services/api'
import { IInvalidPathData } from '~/types/index'

export const useInvalidPath = () => {
  const [invalidPathData, setInvalidPathData] = useState<IInvalidPathData | null>(null)

  useEffect(() => {
    ;(async () => {
      const result = await readInvalidPath()
      typeof result !== 'string' && setInvalidPathData(result?.response)
    })()
  }, [setInvalidPathData])

  return {
    invalidPathData,
    showButton: invalidPathData?.button?.show,
    buttonType: invalidPathData?.button?.type.toLowerCase(),
  }
}
