import { ButtonV3 as Button } from '@provi/provi-components'
import NextLink from 'next/link'
import { useMemo } from 'react'
import ExternalArrowIcon from '~/components/atoms/ExternalArrowIcon'
import FinancingTextBox from '~/components/atoms/FinancingTextBox'
import LoadingIcon from '~/components/atoms/LoadingIcon'
import FeedbackHeaderCheckout from '~/components/molecules/FeedbackHeaderCheckout'
import Tip from '~/components/molecules/Tip'
import { useFinancingSuccess } from './hooks'
import * as S from './style'

const FinancingSuccess = () => {
  const { coursesText, partnerName, hasUpfront, upfrontLink, buttonProps, isChangingPage, setIsChangingPage } =
    useFinancingSuccess()

  const renderBottomContainer = useMemo(() => {
    return (
      <S.BottomContainer>
        <Tip text="O pagamento com boleto pode levar até 3 dias úteis para ser compensado pelo banco." />
        <NextLink href={upfrontLink || '#'} passHref prefetch={true}>
          <Button
            {...buttonProps}
            as="a"
            onClick={() => {
              if (upfrontLink) {
                setIsChangingPage(true)

                setTimeout(() => {
                  setIsChangingPage(false)
                }, 5000)
              }
            }}
            isLoading={isChangingPage}
            text={upfrontLink ? 'Acessar fatura' : 'Carregando'}
            mobileWidth={'100%'}
            disabled={!upfrontLink}
            buttonProps={
              isChangingPage
                ? null
                : {
                    endIcon: upfrontLink ? <ExternalArrowIcon /> : <LoadingIcon iconWidth="20px" />,
                  }
            }
          />
        </NextLink>
      </S.BottomContainer>
    )
  }, [upfrontLink, buttonProps, isChangingPage, setIsChangingPage])

  const renderFinancingSuccessData = useMemo(() => {
    if (!coursesText && process.env.NODE_ENV !== 'test') {
      return (
        <S.FinancingSuccessDataContainer>
          {Array.from({ length: 3 }).map((_, index) => (
            <S.FinancingSuccessLoading key={index} />
          ))}
        </S.FinancingSuccessDataContainer>
      )
    }

    if (!hasUpfront) {
      return (
        <S.FinancingSuccessDataContainer hasUpfront={false}>
          <FinancingTextBox text={`Você contratou o financiamento para ${coursesText} e sua matrícula está confirmada.`} />
          <FinancingTextBox text={`Os próximos passos serão enviados diretamente por ${partnerName} por e-mail.`} />
          <FinancingTextBox text="Até mais!" />
        </S.FinancingSuccessDataContainer>
      )
    }

    return (
      <S.FinancingSuccessDataContainer>
        <FinancingTextBox title="Para concluir o seu financiamento, é necessário efetuar o pagamento da entrada." text="" />
        <FinancingTextBox text="Você pode escolher pagar via Pix, boleto ou cartão de crédito. Clique em 'Acessar fatura' e realize o pagamento." />
        <FinancingTextBox
          title="Acesso ao curso"
          text={`Assim que o pagamento for identificado, o acesso será enviado por ${partnerName} para o seu e-mail.`}
        />

        {renderBottomContainer}
      </S.FinancingSuccessDataContainer>
    )
  }, [coursesText, partnerName, renderBottomContainer, hasUpfront])

  return (
    <S.FinancingSuccessWrapper>
      <FeedbackHeaderCheckout
        isSuccess={true}
        title={hasUpfront ? 'Finalizar compra' : 'Contrato assinado'}
        hasIcon={hasUpfront ? false : true}
        isLoading={!coursesText}
      />
      {renderFinancingSuccessData}
    </S.FinancingSuccessWrapper>
  )
}

export default FinancingSuccess
