import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { writeCheckoutStatus, readCheckoutFail } from '~/services/api'

export const useCheckoutFailureByMethod = () => {
  const { chosenPaymentMethod, step } = useContext(CheckoutContext)
  const [descriptionError, setDescriptionError] = useState<string>('')

  useEffect(() => {
    async function getDescriptionError() {
      const result = await readCheckoutFail()
      typeof result !== 'string' && setDescriptionError(result?.response?.description || 'Houve uma falha no pagamento')
    }

    getDescriptionError()
  }, [])

  const handleTryAgainCreditCard = useCallback(async () => {
    await writeCheckoutStatus({
      currentScreen: step,
    })
  }, [step])

  return {
    chosenPaymentMethod,
    handleTryAgainCreditCard,
    descriptionError,
  }
}
