import { theme } from '@provi/provi-components'
import Webcam from 'react-webcam'
import styled from 'styled-components'
import { Body2, H1 } from '~/styles/global'

export const Container = styled.div`
  width: 100%;
  height: 496px;
  display: flex;
  position: relative;
  flex-direction: column;

  h2 {
    margin-top: 20px;
  }

  #box-camera {
    border-radius: 16px;
  }

  #box-camera #camera--canvas,
  #box-camera #camera--video {
    object-fit: cover;
  }

  ${theme.breakpoints.down('md')} {
    align-items: center;
    height: 100%;
    position: fixed;

    #box-camera {
      border-radius: 0;
    }
  }
`

export const InnerContainerWrapper = styled.div`
  background-color: ${theme.colors.grey500};

  margin-top: 24px;
  margin-left: 24px;

  height: 276px;
  width: 276px;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    max-width: 312px;
    max-height: 312px;
  }
`

export const InnerImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Label = styled.label`
  margin-top: 24px;
  margin-left: 24px;
  max-width: 312px;

  ${theme.breakpoints.down('md')} {
    margin-left: 10px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 368px;
  justify-content: space-between;
  margin-left: 24px;
  margin-bottom: 16px;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    gap: 8px;
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    height: 100px;
    width: 345px;

    button {
      width: 340px;
    }
  }
`

export const WebcamWrapper = styled.div`
  width: 100%;
  height: 67vh;
  max-height: 67vh;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  border-radius: 16px;

  ${theme.breakpoints.down('md')} {
    height: 100vh;
    max-height: unset;
    z-index: 1;
    border-radius: 0px;
  }

  button {
    position: absolute;
    bottom: 16px;
    width: 56px;
    height: 56px;
    border: 0px;
    background-color: ${theme.colors.white100};
    border-radius: 100px;
    transition: border-width 0.1s;

    :hover {
      cursor: pointer;
      border-color: ${theme.colors.bluePrimary};
      border-width: 2px;
      border-style: solid;
    }
  }
`

export const FaceCircle = styled.canvas`
  height: 360px;
  width: 280px;
  border: 6px solid ${theme.colors.white100};
  border-radius: 120px;
  position: absolute;
  box-shadow: -1px 1px 5px 999px rgba(0, 0, 0, 0.75);
`
export const WebcamComponent = styled(Webcam)`
  width: 100%;
  max-height: 67vh;
  background-size: cover;
  object-fit: cover;
  border-radius: 16px;

  ${theme.breakpoints.down('md')} {
    height: 100vh;
    max-height: unset;
    z-index: 1;
    border-radius: 0;
  }
`

export const DeniedWebcamContainer = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  svg {
    margin-left: 4px;
  }
`

export const DeniedWebcamTitle = styled(H1)``

export const DeniedWebcamText = styled(Body2)``

export const DeniedWebcamOrderedList = styled.ol`
  margin-left: 16px;
`

export const DeniedWebcamListItem = styled.li``

export const DeniedWebcamButton = styled(Body2)`
  background-color: transparent;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-decoration: underline;
  color: ${theme.colors.bluePrimary};
  cursor: pointer;

  :hover {
    color: ${theme.colors.bluePrimaryDark};
  }
`

export const DeniedWebcamSpacing = styled.div`
  height: 8px;
`

export const BackButtonWebcam = styled.div`
  z-index: 1;
  background: ${theme.colors.white100};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: 10px;

  > button {
    margin: 0;
  }
`
