import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, loadingCSS } from '~/styles/global'

export const ValidateDataWrapper = styled.div`
  padding: 38px 24px 20px;

  > :first-child {
    max-width: 368px;
  }
`

export const DataContent = styled.ul`
  margin: 29px 0;

  > p {
    margin-bottom: 15px;
  }
`
export const OverlineData = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  span,
  p {
    margin-bottom: 8px;
    text-transform: none;
  }

  :first-of-type {
    margin-bottom: 29px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 46px;
`

export const InfoWrapper = styled(Body2)``

export const LoadingDataContent = styled.div`
  width: 100%;
  max-width: 368px;
  height: 120px;
  border-radius: 8px;

  ${loadingCSS}
`

export const EditReview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

export const WrapperInput = styled.div``

export const SelectorWrapper = styled.div``

export const SelectContent = styled.select`
  width: 100%;
  height: 48px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 3px;
  padding: 8px;
  font-family: 'Montserrat', sans-serif;
  background: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  :focus,
  :focus-visible,
  :focus-within {
    outline: 2px auto ${theme.colors.bluePrimary};
  }

  /* Change default <select /> arrow */
  background: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 20 20' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path></svg>")
    no-repeat;
  background-position: calc(100% - 8px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const LabelSelector = styled(Body2)`
  font-weight: 600;
  margin-bottom: 8px;
`
