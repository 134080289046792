import { theme } from '@provi/provi-components'

const ExternalArrowIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M3.5 17L17.5 3M17.5 3H6.125M17.5 3V14.375"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ExternalArrowIcon
