import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KeyboardEvent, useCallback, useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readCouponCode, writeCouponCode } from '~/services/api'

interface IProceed {
  coupon: string
}

Yup.setLocale({
  string: {
    min: 'Deve ter mais de ${min} digitos',
    max: 'Deve ter menos de ${max} digitos',
  },
})

export const useCouponCode = () => {
  const { isSendingData, isLoadingData, step } = useContext(CheckoutContext)

  useEffect(() => {
    const fetchCouponCode = async () => {
      await readCouponCode(step)
    }

    fetchCouponCode()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const proceed = useCallback(
    (e: IProceed) => {
      const cleanCode = e.coupon === '' ? null : e.coupon

      writeCouponCode(step, cleanCode)
    },
    [step],
  )

  const formikForm = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      coupon: '',
    },
    validationSchema: Yup.object({
      coupon: Yup.string().trim().min(1).max(255),
    }),

    onSubmit: proceed,
  })

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLFormElement>) => {
      if (event.key === 'Enter') {
        !isSendingData && formikForm.handleSubmit()
      }
    },
    [isSendingData, formikForm],
  )

  return { formikForm, handleEnterKey, isSendingData, isLoadingData }
}
