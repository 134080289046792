import { ButtonV3 as Button } from '@provi/provi-components'
import { useMemo } from 'react'
import ShareIcon from '~/components/atoms/ShareIcon'
import FeedbackHeaderCheckout from '~/components/molecules/FeedbackHeaderCheckout/index'
import { copyToClipboard } from '~/utils/copyToClipboard'
import { useCreditInAnalysis } from './hooks'
import * as S from './style'

const CreditInAnalysis = () => {
  const { isWaitingGuarantor, creditInAnalysis, socialTexts, handleShare, headerText, textBodyByProductType, productType } =
    useCreditInAnalysis()

  const renderReviewFlow = useMemo(() => {
    if (productType === 'ISA') {
      return (
        <>
          <S.CreditInAnalysisText>{textBodyByProductType?.[productType]?.wasInReview}</S.CreditInAnalysisText>
        </>
      )
    }

    return <S.CreditInAnalysisText>{textBodyByProductType?.[productType]?.wasInReview}</S.CreditInAnalysisText>
  }, [productType, textBodyByProductType])

  const renderNormalAnalysis = useMemo(() => {
    if (productType === 'ISA') {
      return (
        <>
          <S.CreditInAnalysisText>{textBodyByProductType?.[productType]?.assessmentAnswered}</S.CreditInAnalysisText>
        </>
      )
    }

    return (
      <S.CreditInAnalysisText>
        Vamos analisar os dados enviados e quando estiver tudo pronto para continuar, você receberá a confirmação por e-mail.
        Isso pode demorar até 1 dia útil.
      </S.CreditInAnalysisText>
    )
  }, [productType, textBodyByProductType])

  const renderWaitingIncompleteGuarantor = useMemo(() => {
    return (
      <S.CreditInAnalysisIncompleteGuarantor>
        <S.CreditInAnalysisText>
          Precisamos dos documentos da pessoa avalista que você indicou. Compartilhe essa mensagem ou, se preferir, copie o
          link:
        </S.CreditInAnalysisText>

        <S.CreditInAnalysisSocialBox data-recording-sensitive data-clarity-mask="true">
          {socialTexts?.htmlTexts?.map((text) => text)}
        </S.CreditInAnalysisSocialBox>

        <S.ButtonsContainer>
          <Button
            color="white"
            text="Copiar link"
            width="fit-content"
            mobileWidth="100%"
            onClick={() => copyToClipboard(socialTexts?.guarantorURL || '')}
          />
          <Button
            text="Compartilhar mensagem"
            width="272px"
            mobileWidth="100%"
            buttonProps={{ endIcon: <ShareIcon /> }}
            onClick={handleShare}
          />
        </S.ButtonsContainer>
      </S.CreditInAnalysisIncompleteGuarantor>
    )
  }, [socialTexts, handleShare])

  const renderCreditInAnalysisContent = useMemo(() => {
    if (!creditInAnalysis && process.env.NODE_ENV !== 'test') {
      return <S.CreditInAnalysisLoadingBlock />
    }

    if (creditInAnalysis?.wasInReview) {
      return renderReviewFlow
    }

    return isWaitingGuarantor ? renderWaitingIncompleteGuarantor : renderNormalAnalysis
  }, [creditInAnalysis, isWaitingGuarantor, renderReviewFlow, renderWaitingIncompleteGuarantor, renderNormalAnalysis])

  return (
    <>
      <FeedbackHeaderCheckout
        isSuccess={true}
        title={
          creditInAnalysis &&
          (creditInAnalysis?.wasInReview
            ? headerText.wasInReview
            : isWaitingGuarantor
            ? headerText.isWaitingGuarantor
            : headerText.isNotWaitingGuarantor)
        }
      />
      <S.CreditInAnalysisContainer>{renderCreditInAnalysisContent}</S.CreditInAnalysisContainer>
    </>
  )
}

export default CreditInAnalysis
