import { ButtonV3 as Button } from '@provi/provi-components'
import { LoadingInput } from '~/components/molecules'
import { creditDeniedText } from '~/components/templates/CreditPath/CreditDenied/constants'
import { Body1, Body2 } from '~/styles/global'
import { useCreditDeniedScreen } from './hooks'

export const CreditDeniedScreen = () => {
  const { isLoadingData, isSendingData, handleNextScreen, canSelectNewPaymentMethod } = useCreditDeniedScreen()

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return (
      <div className="max-w-[368px] p-6">
        <LoadingInput />
      </div>
    )
  }

  return (
    <div className="flex flex-col p-6 space-y-52">
      <div className="flex flex-col space-y-4 max-w-[368px]">
        <Body1>Infelizmente, não conseguimos aprovar seu crédito</Body1>
        <Body2>
          {canSelectNewPaymentMethod
            ? creditDeniedText.canSelectNewPaymentMethod
            : creditDeniedText.cannotSelectNewPaymentMethod}
        </Body2>
      </div>
      {canSelectNewPaymentMethod && (
        <Button
          text="Alterar forma de pagamento"
          icon
          onClick={handleNextScreen}
          isLoading={isSendingData}
          mobileWidth="100%"
        />
      )}
    </div>
  )
}
