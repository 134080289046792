import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readReviewInfo, writeReviewInfo } from '~/services/api'
import { ICepPromise, IResponseReviewInfo } from '~/types/index'
import { brazilianStates } from '../AddressScreen/states'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { getLastName, isEmailValid, isPhoneValid, validateCEP, validateFullName } from '~/utils/validation'
import { removeMask } from '~/utils/removeMask'
import cep from 'cep-promise'

export const useValidateData = () => {
  const { step, isSendingData, isLoadingData, setIsLoadingData } = useContext(CheckoutContext)
  const [isInputLoading, setIsInputLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [information, setInformation] = useState<IResponseReviewInfo>({
    CPF: '',
    RG: '',
    city: '',
    complement: '',
    district: '',
    email: '',
    fullName: '',
    fullSocialName: '',
    streetNumber: '',
    phone: '',
    state: '',
    street: '',
    zipcode: '',
  })

  const brazilianState = brazilianStates.find((option) => option.acronym === information?.state)

  const zipCodeFormatted = information?.zipcode.replace(/(\d{5})/gi, '$1-').replace(/-$/gi, '')

  const cpfFormatted = information?.CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

  const phoneFormatted = information?.phone.replace(/\D+/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')

  useEffect(() => {
    setIsLoadingData(true)

    async function getReadReviewInfo() {
      const { response } = await readReviewInfo()
      setInformation(response)
    }

    getReadReviewInfo().finally(() => setIsLoadingData(false))
  }, [setIsLoadingData, setInformation])

  const toggleEdit = useCallback(() => {
    setIsEditing((prev) => !prev)
  }, [setIsEditing])

  const submitNewEditChanges = useCallback((values) => {
    console.log(values)
  }, [])

  const formikForm = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: information,
    initialTouched: Object.keys(information || {}).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {}),
    enableReinitialize: true,
    validateOnMount: true,

    validationSchema: Yup.object({
      CPF: Yup.string().required(requiredErrorMessages.cpf).min(11, errorMessages.cpf),
      fullName: Yup.string().required(requiredErrorMessages.fullName),
      fullSocialName: Yup.string().notRequired().nullable(),
      phone: Yup.string().required(requiredErrorMessages.phone),
      email: Yup.string().email().required(requiredErrorMessages.emailInvalid),
      zipcode: Yup.string().required(requiredErrorMessages.cep),
      street: Yup.string().required(requiredErrorMessages.street).min(4, errorMessages.street),
      streetNumber: Yup.string().required(requiredErrorMessages.streetNumber),
      state: Yup.string().required(requiredErrorMessages.state),
      city: Yup.string().required(requiredErrorMessages.city).min(3, errorMessages.city),
      district: Yup.string().required(requiredErrorMessages.district).min(3, errorMessages.district),
      complement: Yup.string().notRequired().nullable(),
    }),

    onSubmit: submitNewEditChanges,

    validate: (fields: IResponseReviewInfo) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if ((cur === 'fullName' || cur === 'fullSocialName') && fields[cur] && !validateFullName(fields[cur])) {
          const lastName = getLastName(fields[cur])[0]
          if (!fields[cur].includes(' ') || (lastName && lastName.length < 2) || !lastName) {
            return {
              ...prev,
              [cur]: errorMessages.fullName,
            }
          }

          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'phone' && fields[cur] && !isPhoneValid(fields[cur])) {
          if (fields[cur].length === 1) {
            return {
              ...prev,
              [cur]: requiredErrorMessages.phone,
            }
          }
          return {
            ...prev,
            [cur]: errorMessages.phone,
          }
        }

        if (cur === 'email' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        if (cur === 'zipcode' && fields[cur] && !validateCEP(fields[cur]) && removeMask(fields[cur]).length !== 8) {
          return {
            ...prev,
            [cur]: errorMessages.cep,
          }
        }

        return { ...prev }
      }, {})
    },
  })

  const brazilianStatesSelector = brazilianStates.find((option) => option.acronym === formikForm.values.state)

  const getObjectFromAcronym = useCallback(
    (acronym: string) => {
      const filteredObject = brazilianStates.find((option) => option.acronym === acronym)
      return formikForm.setFieldValue('state', filteredObject?.acronym)
    },
    [formikForm],
  )

  const handleSubmitReviewInfo = useCallback(async () => {
    const data = isEditing ? formikForm.values : information

    const nullableData = Object.keys(data || {}).reduce((prev, cur) => {
      if (data[cur] === '') {
        prev[cur] = null
      } else {
        prev[cur] = data[cur]
      }
      return prev
    }, {}) as IResponseReviewInfo

    await writeReviewInfo({
      currentScreen: step,
      data: nullableData,
    })
  }, [step, information, isEditing, formikForm.values])

  useEffect(() => {
    if (formikForm.values.zipcode && formikForm.values.zipcode.length === 9) {
      setIsInputLoading(true)

      cep(formikForm.values.zipcode)
        .then((data: ICepPromise) => {
          formikForm.setFieldValue('state', data?.state || '')
          formikForm.setFieldValue('neighborhood', data?.neighborhood || '')
          formikForm.setFieldValue('district', data?.neighborhood || '')
          formikForm.setFieldValue('city', data?.city || '')
          formikForm.setFieldValue('street', data?.street || '')

          data.state && formikForm.setFieldTouched('state')
          data.neighborhood && formikForm.setFieldTouched('neighborhood')
          data.neighborhood && formikForm.setFieldTouched('district')
          data.city && formikForm.setFieldTouched('city')
          data.street && formikForm.setFieldTouched('street')

          setIsInputLoading(false)
        })
        .catch(() => {
          document.getElementById('react-select-2-input')?.focus()
        })
        .finally(() => setIsInputLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikForm.values.zipcode, setIsInputLoading])

  return {
    brazilianState,
    zipCodeFormatted,
    cpfFormatted,
    phoneFormatted,
    handleSubmitReviewInfo,
    information,
    isSendingData,
    isLoadingData,
    isEditing,
    toggleEdit,
    formikForm,
    brazilianStatesSelector,
    getObjectFromAcronym,
    isInputLoading,
  }
}
