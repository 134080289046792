import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { loadingCSS } from '~/styles/global'

export const FinancingSuccessWrapper = styled.div``

export const FinancingSuccessDataContainer = styled.div<{ hasUpfront?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ hasUpfront }) => (hasUpfront ? '40px' : '20px')};

  max-width: 368px;
  margin: 46px 24px 35px;

  ${theme.breakpoints.down('md')} {
    margin: 33px 24px 35px;
    gap: ${({ hasUpfront }) => (hasUpfront ? '23px' : '14px')};
  }
`

export const FinancingSuccessLoading = styled.div`
  ${loadingCSS};

  width: 100%;
  max-width: 368px;
  height: 120px;

  border-radius: 8px;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-top: 10px;

  ${theme.breakpoints.down('md')} {
    margin-top: 20px;
  }
`
