import { useEffect, useState } from 'react'
import { readGuarantorDenied } from '~/services/api'
import { IReadGuarantorDeniedResponse } from 'types'

export const useGuarantorDenied = () => {
  const [guarantorData, setGuarantorData] = useState<IReadGuarantorDeniedResponse | null>(null)

  useEffect(() => {
    const fetchGuarantorDenied = async () => {
      const data = await readGuarantorDenied()

      if (typeof data !== 'string') {
        setGuarantorData(data.response)
      }
    }

    fetchGuarantorDenied()
  }, [])

  return { guarantorData }
}
