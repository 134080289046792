import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ContractContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  .label-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;

    ${theme.breakpoints.down('md')} {
      font-weight: 500;
      font-size: 15.84px;
    }
  }
`

export const ContractItemContainer = styled.div`
  margin-top: 35px;
  max-width: 368px;

  .label-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      padding-right: 24px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0075em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.005em;
    }
  }

  div {
    margin-top: 24px;

    ul {
      margin-left: 32px;
    }

    ${theme.breakpoints.down('md')} {
      margin-top: 8px;

      ul {
        margin-left: 24px;
      }
    }

    p {
      ${theme.breakpoints.down('md')} {
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: left;
      }
    }
  }
`

export const ContractInformationContainer = styled.div`
  background-color: ${theme.colors.bluePrimaryLight};
  height: 84px;
  width: 368px;
  left: 0px;
  top: 138px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  margin-top: 42px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 368px;
  }

  label {
    margin-left: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.005em;
    text-align: left;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 40px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  button {
    border: 0px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`
