import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body1, Body2, Button, loadingCSS } from '~/styles/global'

interface IDateCellsText {
  justifyContent: string
  isSubTitleReview?: boolean
}

interface IDateCellsWrapper {
  shouldShowMore: boolean
  cellsCount: number
}

export const FinancingSummaryWrapper = styled.div`
  padding: 35px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const SummaryReviewWrapper = styled.div`
  margin: 12px 0 24px;
  max-width: 368px;

  > p:nth-child(1) {
    color: ${theme.colors.bluePrimary};
    font-weight: bold;
    text-transform: uppercase;
  }

  hr {
    height: 1px;
    border: 0px;
    border-top: 2px solid ${theme.colors.bluePrimaryLight};
    margin: 16px 0;
  }
`

export const FinancingSummaryTitle = styled(Body1)``

export const SummaryReviewTitle = styled(Body2)``

export const SummaryReviewContainer = styled.div`
  border-bottom: 2px solid ${theme.colors.bluePrimary};
  margin-bottom: 22px;
`

export const SummaryReviewContent = styled.div`
  > p:nth-child(1) {
    font-weight: 600;
  }

  margin-bottom: 22px;
`

export const SummaryRowLoading = styled.div`
  ${loadingCSS};

  width: 100%;
  max-width: 368px;
  min-height: 48px;
  border-radius: 8px;
`

export const SeeMore = styled(Button)``

export const DateCellsWrapper = styled.div<IDateCellsWrapper>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px 0;

  width: 100%;
  max-height: 122px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: default;
  transition: max-height 0.3s ease-in-out;

  ${({ shouldShowMore, cellsCount }) =>
    shouldShowMore &&
    cellsCount &&
    css`
      max-height: calc(${cellsCount} * 60px);
    `}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${theme.breakpoints.down('md')} {
    max-height: 106px;

    ${({ shouldShowMore, cellsCount }) =>
      shouldShowMore &&
      cellsCount &&
      css`
        max-height: calc(${cellsCount} * 53px);
      `}
  }
`

export const DateCellsText = styled(Body2)<IDateCellsText>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  border-bottom: 1px solid ${theme.colors.bluePrimaryLight};

  :nth-last-child(-n + 3) {
    border: none;
  }

  :nth-child(2) {
    padding: 0 8px;
  }

  ${({ isSubTitleReview }) =>
    isSubTitleReview &&
    css`
      margin-bottom: 10px;
    `}
`

export const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;

  .label-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;

    ${theme.breakpoints.down('md')} {
      font-weight: 500;
      font-size: 15.84px;
    }
  }
`

export const ContractItemContainer = styled.div`
  margin-top: 12px;
  max-width: 368px;

  .label-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      padding-right: 24px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0075em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.005em;
    }
  }

  div {
    margin-top: 24px;

    ul {
      margin-left: 32px;
    }

    ${theme.breakpoints.down('md')} {
      margin-top: 8px;

      ul {
        margin-left: 24px;
      }
    }

    p {
      ${theme.breakpoints.down('md')} {
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: left;
      }
    }
  }
`

export const ContractInformationContainer = styled.div`
  background-color: ${theme.colors.bluePrimaryLight};
  max-width: 368px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }

  label {
    margin-left: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.005em;
    text-align: left;
  }
`
