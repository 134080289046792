import { useMemo } from 'react'
import { FeedbackHeaderCheckout, LoadingRing } from '~/components/molecules'
import { useLoadingAnalysis } from './hooks'
import * as S from './style'

const LoadingAnalysis = () => {
  const { hasTimedOut } = useLoadingAnalysis()

  const renderLoading = useMemo(() => {
    return <LoadingRing />
  }, [])

  const renderTimeout = useMemo(() => {
    return (
      <S.BasicInfoTimeout>
        <FeedbackHeaderCheckout isSuccess={true} title="Obrigada por aguardar" />
        <S.BasicInfoTimeoutText>Fique de olho no seu e-mail para saber os próximos passos. Até já!</S.BasicInfoTimeoutText>
      </S.BasicInfoTimeout>
    )
  }, [])

  return <S.BasicInfoAnalysisWrapper>{hasTimedOut ? renderTimeout : renderLoading}</S.BasicInfoAnalysisWrapper>
}

export default LoadingAnalysis
