import { useMemo } from 'react'
import { textsMethodCreditCard, textsMethodPix, textsMethodTicket } from '~/enums/textsPaymentMethodTicket'
import CheckoutSuccess from '../../CheckoutSuccess'
import { useCheckoutSuccessByMethod } from './hooks'

const CheckoutSuccessByMethod = () => {
  const { chosenPaymentMethod, checkoutSuccessObject } = useCheckoutSuccessByMethod()
  const isPaid = checkoutSuccessObject?.paymentInfo?.status === 'paid' || false

  const paidTitleText = isPaid ? 'O pagamento foi confirmado!' : ''

  const renderCheckoutSuccess = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'Boleto':
        return (
          <CheckoutSuccess
            isSuccess
            text={textsMethodTicket.success.text}
            title={paidTitleText || textsMethodTicket.success.title}
            buttonText="Visualizar boleto"
            isWithIcon
            isTicket
          />
        )
      case 'Pix':
        return (
          <CheckoutSuccess
            isSuccess
            text={textsMethodPix.success.text}
            title={paidTitleText || textsMethodPix.success.title}
            isPix
          />
        )
      case 'Cartão de crédito':
      case 'CreditCard':
      default:
        return (
          <CheckoutSuccess isSuccess text={textsMethodCreditCard.success.text} title={textsMethodCreditCard.success.title} />
        )
    }
  }, [chosenPaymentMethod, paidTitleText])

  return renderCheckoutSuccess || null
}

export default CheckoutSuccessByMethod
