import { getStorage, ref, getDownloadURL, uploadString } from 'firebase/storage'
import { toast } from 'react-toastify'
import { initializeFirebase } from './initializeFirebase'

const createRandomName = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export const uploadToFirebase = async (file: string, partnerId: string, isText = false): Promise<string | null> => {
  try {
    // initialize firebase
    const firebaseApp = await initializeFirebase(process.env.NEXT_PUBLIC_API_ENV as string)

    const extension = isText ? 'json' : 'jpeg'
    const contentType = isText ? 'application/json' : 'image/jpeg'
    const convertedFile = isText ? JSON.stringify({ jwt: file }) : file.substring(file.indexOf(',') + 1)
    const format = isText ? 'raw' : 'base64'

    // create firebase reference
    const storage = getStorage(firebaseApp)
    const storagePath = `/${partnerId || 'no-partner-id'}/${Date.now()}-${createRandomName()}.${extension}`
    const storageRef = ref(storage, storagePath)

    // upload file to firebase
    await uploadString(storageRef, convertedFile, format, {
      cacheControl: 'public, max-age=31536000',
      contentType: contentType,
    })

    // return url
    return getDownloadURL(storageRef)
  } catch (err) {
    console.log({ err })

    toast.error('Erro ao enviar imagem para o servidor', {
      toastId: 'uploadToFirebase',
    })
  }

  return null
}
