import { ButtonV3 as Button, InputFieldV3 as Input, SelectorV3 as Selector } from '@provi/provi-components'
import { ChangeEvent, useMemo } from 'react'
import { LoadingInput } from '~/components/molecules'
import { masks } from '~/enums/masks'
import { Body1 } from '~/styles/global'
import { IBrazilianState } from '~/types/index'
import { useWindowSize } from '~/utils/useWindowSize'
import { useAddressTab } from './hooks'
import { brazilianStates } from './states'
import * as S from './style'

export const AddressScreen = () => {
  const {
    values,
    brazilianStatesSelector,
    errors,
    touched,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    handleEnterKey,
    setFieldTouched,
    validateForm,
    streetNumberInputRef,
    isLoadingData,
    isSendingData,
    nbhInputRef,
    getObjectFromAcronym,
    isInputLoading,
  } = useAddressTab()

  const { width } = useWindowSize()

  const renderSelectorByScreenSize = useMemo(() => {
    if (width && width < 800) {
      return (
        <S.SelectorWrapper>
          <S.LabelSelector>Estado</S.LabelSelector>
          <S.SelectContent
            name="state"
            id="input-state"
            placeholder="Selecionar estado"
            value={values.state}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              getObjectFromAcronym(event.target.value)
            }}
          >
            <option value="" hidden>
              Selecionar estado
            </option>
            {brazilianStates.map((state: IBrazilianState) => (
              <option key={state.acronym} value={state.acronym}>
                {state.label}
              </option>
            ))}
          </S.SelectContent>
        </S.SelectorWrapper>
      )
    }

    return (
      <Selector
        id="input-state"
        label="Estado"
        name="state"
        placeholder="Selecionar estado"
        className="cy-select-state"
        isSearchable={false}
        isLoading={isLoadingData}
        value={brazilianStatesSelector}
        options={brazilianStates}
        onChange={(event: typeof brazilianStates[0]) => {
          setFieldValue('state', event.acronym)
        }}
        onBlur={() => {
          setFieldTouched('state')
          validateForm()
        }}
        isValid={!errors.state && touched.state ? true : false}
        hasError={errors.state && touched.state ? true : false}
        onKeyDown={handleEnterKey}
        width="368px"
      />
    )
  }, [
    width,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateForm,
    getObjectFromAcronym,
    brazilianStatesSelector,
    handleEnterKey,
    values.state,
    isLoadingData,
  ])

  const renderFormAddressLoading = useMemo(() => {
    return (
      <S.WrapperForm>
        <Body1>Endereço</Body1>

        <S.WrapperInputLoading>
          <LoadingInput inputWidth="184px" />
          <LoadingInput />
          <LoadingInput />
          <LoadingInput />
          <LoadingInput />
          <S.InputContentLoading>
            <LoadingInput />
            <LoadingInput />
          </S.InputContentLoading>
          <S.AddressLoadingButton />
        </S.WrapperInputLoading>
      </S.WrapperForm>
    )
  }, [])

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return renderFormAddressLoading
  }

  return (
    <S.WrapperForm>
      <Body1>Endereço</Body1>

      <S.WrapperInput>
        <Input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="CEP"
          placeholder="23530-024"
          width="160px"
          name="zipcode"
          mask={masks.cep}
          value={values.zipcode}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('zipcode', e.target.value)}
          onBlur={() => {
            setFieldTouched('zipcode')
            validateForm()
          }}
          isValid={!errors.zipcode && touched.zipcode}
          hasError={errors.zipcode && touched.zipcode}
          errorMessage={errors.zipcode}
          onKeyDown={handleEnterKey}
        />
        {renderSelectorByScreenSize}
        <Input
          label="Cidade"
          placeholder="Rio de Janeiro"
          width="368px"
          name="city"
          value={values.city}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('city', e.target.value)}
          onBlur={() => {
            setFieldTouched('city')
            validateForm()
          }}
          isValid={!errors.city && touched.city}
          hasError={errors.city && touched.city}
          errorMessage={errors.city}
          onKeyDown={handleEnterKey}
          isLoading={isInputLoading}
        />
        <Input
          label="Bairro"
          placeholder="Rio Comprido"
          width="368px"
          value={values.district}
          name="district"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('district', e.target.value)}
          onBlur={() => {
            setFieldTouched('district')
            validateForm()
          }}
          isValid={!errors.district && touched.district}
          hasError={errors.district && touched.district}
          errorMessage={errors.district}
          onKeyDown={handleEnterKey}
          inputRef={nbhInputRef}
          isLoading={isInputLoading}
        />
        <Input
          label="Endereço"
          placeholder="Rua Lourival Lópes"
          width="368px"
          value={values.street}
          name="street"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('street', e.target.value)}
          onBlur={() => {
            setFieldTouched('street')
            validateForm()
          }}
          isValid={!errors.street && touched.street}
          hasError={errors.street && touched.street}
          errorMessage={errors.street}
          onKeyDown={handleEnterKey}
          isLoading={isInputLoading}
        />
        <S.InputContent>
          <Input
            label="Número"
            placeholder="119"
            width="160px"
            value={values.streetNumber}
            name="streetNumber"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('streetNumber', e.target.value)}
            onBlur={() => {
              setFieldTouched('streetNumber')
              validateForm()
            }}
            isValid={!errors.streetNumber && touched.streetNumber}
            hasError={errors.streetNumber && touched.streetNumber}
            errorMessage={errors.streetNumber}
            onKeyDown={handleEnterKey}
            inputRef={streetNumberInputRef}
            isLoading={isInputLoading}
          />
          <Input
            label="Complemento"
            placeholder="Apto 13"
            width="184px"
            value={values.complement || ''}
            name="complement"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('complement', e.target.value)}
            onBlur={() => {
              setFieldTouched('complement')
              validateForm()
            }}
            isValid={!errors.complement && touched.complement && values.complement}
            hasError={errors.complement && touched.complement}
            errorMessage={errors.complement}
            onKeyDown={handleEnterKey}
            isLoading={isInputLoading}
          />
        </S.InputContent>
        <S.ButtonWrapper>
          <Button
            text="Continuar"
            type="submit"
            icon
            onClick={handleSubmit}
            disabled={!(isValid && dirty)}
            isLoading={isSendingData}
            mobileWidth="100%"
          />
        </S.ButtonWrapper>
      </S.WrapperInput>
    </S.WrapperForm>
  )
}
