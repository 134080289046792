import * as S from './styles'

export const InputLabelWithDescription = ({ label, description }: { label: string; description: string }) => {
  return (
    <S.InputLabelWithDescriptionContainer>
      <S.InputLabelWithDescriptionLabel>{label}</S.InputLabelWithDescriptionLabel>
      <S.InputLabelWithDescriptionDescription>{description}</S.InputLabelWithDescriptionDescription>
    </S.InputLabelWithDescriptionContainer>
  )
}
