import { useCallback, useContext, useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CreditPathContext } from '~/contexts/CreditPathContext'
import { uploadImage } from '~/adapters/uploadImage'
import { readInsertDocument, writeInsertDocument } from '~/services/api'
import { IStep } from 'types'

interface IUseInsertDocument {
  videoRef: React.RefObject<Webcam>
}

export const useInsertDocument = ({ videoRef }: IUseInsertDocument) => {
  const { step, setStep, isSendingData, partnerId } = useContext(CheckoutContext)
  const {
    isWebcamOpen,
    setIsWebcamOpen,
    documentImage,
    setDocumentImage,
    documentType,
    setDocumentType,
    openFilePicker,
    shouldMockPNG,
    unicoFailed,
    initUnico,
    isSuccessful,
    setIsSuccessful,
  } = useContext(CreditPathContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [nextScreen, setNextScreen] = useState<IStep | null>(null)
  const [completedDocumentFlow, setCompletedDocumentFlow] = useState<boolean>(false)

  const hasNextStep = nextScreen === 'insertRGBack'

  const callOpenFilePicker = useCallback(() => {
    setDocumentType('upload')
    openFilePicker({ setIsSuccessful, setNextScreen })
  }, [openFilePicker, setIsSuccessful, setNextScreen, setDocumentType])

  useEffect(() => {
    if (isWebcamOpen) {
      setDocumentType('webcam')
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebcamOpen])

  useEffect(() => {
    const readDocument = async () => {
      await readInsertDocument(step)
    }

    readDocument()
  }, [step])

  const captureImage = useCallback(() => {
    setIsSuccessful(false)
    setIsLoading(false)

    const imageSrc = videoRef.current.getScreenshot()

    setDocumentImage(imageSrc)
    setIsWebcamOpen(false)
  }, [videoRef, setIsSuccessful, setIsLoading, setDocumentImage, setIsWebcamOpen])

  const retryImage = useCallback(() => {
    setDocumentImage(null)

    if (documentType === 'upload') {
      callOpenFilePicker()
      return
    }

    setIsWebcamOpen(true)
    initUnico()
  }, [documentType, setDocumentImage, callOpenFilePicker, initUnico, setIsWebcamOpen])

  const getImageExtension = useCallback((base64: string) => {
    return base64.split(';')[0].split('/')[1].toUpperCase() || 'PNG'
  }, [])

  const confirmImage = useCallback(
    async (isDesktop?: boolean) => {
      console.log('[client] - confirmImage fn')
      if (isSuccessful && hasNextStep && nextScreen) {
        !isDesktop && setIsSuccessful(false)
        !completedDocumentFlow && setNextScreen(null)
        setIsWebcamOpen(false)

        return
      }

      try {
        setIsLoading(true)

        if (documentImage) {
          const imageUrl = await uploadImage(documentImage, partnerId?.toString())

          if (imageUrl) {
            try {
              const response = await writeInsertDocument({
                currentScreen: step,
                data: {
                  url: imageUrl,
                  typeExtension: shouldMockPNG ? 'PNG' : getImageExtension(documentImage),
                },
              })

              if (typeof response !== 'string') {
                setIsSuccessful(true)
                setNextScreen(response.nextScreen)

                if (response.nextScreen === 'insertRGBack') {
                  setDocumentType(null)
                  // setting step here to avoid a bug where the user would be able to go back to the
                  // document flow screen
                  isDesktop && setStep(response.nextScreen)
                } else {
                  setCompletedDocumentFlow(true)
                }
              }
            } catch (err) {
              setIsSuccessful(false)
              console.error(err)
            }
          }
        }
      } catch (err) {
        setIsSuccessful(false)
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [
      isSuccessful,
      hasNextStep,
      nextScreen,
      setIsWebcamOpen,
      step,
      setStep,
      setIsSuccessful,
      documentImage,
      getImageExtension,
      setDocumentType,
      setNextScreen,
      completedDocumentFlow,
      setCompletedDocumentFlow,
      shouldMockPNG,
      partnerId,
    ],
  )

  const continueFlow = useCallback(() => {
    // setting step here to avoid a bug where the user would be able to go back to the
    // document flow screen
    nextScreen && setStep(nextScreen)
  }, [setStep, nextScreen])

  const getTextToDisplay = useCallback(() => {
    const defaultText =
      'Você precisa tirar uma foto do seu documento. Para isso, basta posicionar o documento em frente à câmera e clicar em "Abrir câmera".'
    const defaultSuccessText = 'Seu documento foi enviado!'
    const frontRGText = 'Pronto! Você pode continuar ou tirar a foto outra vez caso os dados não estejam legíveis.'
    const frontRGSuccessText = 'Ok! Agora, você vai repetir o processo do outro lado do documento.'

    if (!documentImage) {
      return defaultText
    }

    if (isSuccessful) {
      return hasNextStep ? frontRGSuccessText : defaultSuccessText
    }

    return frontRGText
  }, [isSuccessful, documentImage, hasNextStep])

  return {
    getTextToDisplay,
    captureImage,
    isWebcamOpen,
    documentImage,
    retryImage,
    confirmImage,
    isSuccessful,
    isLoading,
    hasNextStep,
    callOpenFilePicker,
    setIsWebcamOpen,
    completedDocumentFlow,
    continueFlow,
    isSendingData,
    initUnico,
    unicoFailed,
  }
}
