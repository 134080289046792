import { useContext, useEffect, useState } from 'react'
import { readLoadingAnalysis, writeIncompleteAnalysis, writeLoadingAnalysis } from '~/services/api'
import { ILoadingAnalysisResponse } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import useCountDown from '~/hooks/useCountdown'

export const useLoadingAnalysis = () => {
  const { step, setStep } = useContext(CheckoutContext)
  const [screenSettings, setScreenSettings] = useState<ILoadingAnalysisResponse | null>(null)
  const { timeLeft, actions } = useCountDown(10000, 1000)
  const { start } = actions

  const hasTimedOut =
    ['incompleteBasicInfoAnalysis', 'incompleteGuarantorAnalysisTimeout', 'incompleteDocumentAnalysisTimeout'].includes(step) ||
    (screenSettings?.screenWaitingTime && timeLeft === 0) ||
    false
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const getLoadingAnalysis = async () => {
      setIsFetching(true)
      const data = await readLoadingAnalysis(step)
      typeof data !== 'string' && setScreenSettings(data.response)
      setIsFetching(false)
    }

    !isFetching && !hasTimedOut && getLoadingAnalysis()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (screenSettings) {
      start(screenSettings?.screenWaitingTime)
    }
  }, [start, screenSettings])

  useEffect(() => {
    if (screenSettings?.timeBetweenRequests) {
      const tryLoadingAnalysis = async () => {
        setIsFetching(true)
        const writeData = await writeLoadingAnalysis(step)

        if (typeof writeData !== 'string' && writeData.nextScreen != step) {
          // setting the step here in case it bugs out
          setStep(writeData.nextScreen)
        }
        setIsFetching(false)
      }

      if (!hasTimedOut && timeLeft % screenSettings.timeBetweenRequests === 0 && !isFetching) {
        tryLoadingAnalysis()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, setStep, timeLeft])

  useEffect(() => {
    const sendTimeoutMessage = async () => {
      const endpoint = step === 'basicInfoAnalysis' ? 'incomplete-basic-info-analysis' : 'incomplete-document-analysis'
      const fallbackStep = step === 'basicInfoAnalysis' ? 'incompleteBasicInfoAnalysis' : 'incompleteDocumentAnalysisTimeout'

      const timeoutData = await writeIncompleteAnalysis(endpoint)
      if (typeof timeoutData !== 'string') {
        // setting the step here in case some ms_ is down at the time and set the the crid to incomplete
        setStep(timeoutData.nextScreen || fallbackStep)
      }
    }

    if (hasTimedOut && ['basicInfoAnalysis', 'documentAnalysis'].includes(step)) {
      sendTimeoutMessage()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimedOut])

  return { hasTimedOut }
}
