import { ButtonV3 as Button } from '@provi/provi-components'
import { LoadingInput } from '~/components/molecules'
import { Body1, Body2 } from '~/styles/global'
import { useEndBasicInfoScreen } from './hooks'

export const EndBasicInfoScreen = () => {
  const { isLoadingData, isSendingData, handleNextScreen } = useEndBasicInfoScreen()

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return (
      <div className="max-w-[368px] p-6">
        <LoadingInput />
      </div>
    )
  }

  return (
    <div className="flex flex-col p-6 space-y-52">
      <div className="flex flex-col space-y-4 max-w-[368px]">
        <Body1>Envie agora seus documentos</Body1>
        <Body2>Na próxima etapa, precisaremos de uma foto de rosto e do seu RG ou carteira de motorista.</Body2>
      </div>
      <Button text="Continuar" icon onClick={handleNextScreen} isLoading={isSendingData} mobileWidth="100%" />
    </div>
  )
}
