import styled from 'styled-components'
import { Body1 } from '~/styles/global'
import { theme } from '@provi/provi-components'

interface IWrapperForm {
  hasChosenPaymentMethod: boolean
}

export const WrapperForm = styled.section<IWrapperForm>`
  display: flex;
  flex-direction: column;
  min-height: 406px;

  margin: ${({ hasChosenPaymentMethod }) => (hasChosenPaymentMethod ? '21px 24px 16px' : '35px 24px 16px')};

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
    align-items: center;
  }
`

export const TitleForm = styled(Body1)`
  margin-bottom: 21px;
`

export const BottomInformation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-end;
  gap: 27px;
`
