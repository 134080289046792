import { useMemo } from 'react'
import { textsMethodCreditCard, textsMethodPix, textsMethodTicket } from '~/enums/textsPaymentMethodTicket'
import CheckoutSuccess from '../../CheckoutSuccess'
import { useCheckoutFailureByMethod } from './hooks'

const CheckoutFailureByMethod = () => {
  const { chosenPaymentMethod, handleTryAgainCreditCard, descriptionError } = useCheckoutFailureByMethod()

  const renderCheckoutFailure = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'Boleto':
        return (
          <CheckoutSuccess isSuccess={false} text={textsMethodTicket.failure.text} title={textsMethodTicket.failure.title} />
        )
      case 'Pix':
        return <CheckoutSuccess isSuccess={false} text={textsMethodPix.failure.text} title={textsMethodPix.failure.title} />
      default:
        return (
          <CheckoutSuccess
            isSuccess={false}
            text={descriptionError}
            title={textsMethodCreditCard.failure.title}
            isWithButton={true}
            buttonText="Quero pagar de outra forma"
            handleTryAgain={handleTryAgainCreditCard}
          />
        )
    }
  }, [chosenPaymentMethod, descriptionError, handleTryAgainCreditCard])

  return renderCheckoutFailure || null
}

export default CheckoutFailureByMethod
