import { useMemo } from 'react'
import IntroGuarantorContent from '~/components/organisms/IntroGuarantorContent'
import { useIntroGuarantor } from './hooks'
import * as S from './style'

const IntroGuarantor = () => {
  const { dataToShow, handleClick, introGuarantorData } = useIntroGuarantor()

  const renderIntroGuarantor = useMemo(() => {
    if (!dataToShow) {
      return (
        <>
          <S.IntroGuarantorLoading />
          <S.IntroGuarantorLoading />
          <S.IntroGuarantorLoading />
        </>
      )
    }

    return <IntroGuarantorContent data={dataToShow} handleClick={handleClick} backendData={introGuarantorData} />
  }, [dataToShow, handleClick, introGuarantorData])

  return <S.IntroGuarantorWrapper>{renderIntroGuarantor}</S.IntroGuarantorWrapper>
}

export default IntroGuarantor
