import { initializeApp } from 'firebase/app'
import { signInAnonymously, getAuth } from 'firebase/auth'
import { ROOT_URL } from '../constants'

export const initializeFirebase = async (environment: string) => {
  let firebaseConfig = null

  if (environment === 'production') {
    firebaseConfig = {
      apiKey: ROOT_URL?.FIREBASE_API_KEY,
      authDomain: 'fe-user-dashboard.firebaseapp.com',
      databaseURL: 'https://fe-user-dashboard.firebaseio.com',
      projectId: 'fe-user-dashboard',
      storageBucket: 'fe-user-dashboard.appspot.com',
      messagingSenderId: '782531872442',
      appId: '1:782531872442:web:eade4e94be3cbba30ba483',
      measurementId: 'G-S62EE6RX6C',
    }
  } else {
    firebaseConfig = {
      apiKey: ROOT_URL?.FIREBASE_API_KEY,
      authDomain: 'fe-user-dashboard-staging.firebaseapp.com',
      databaseURL: 'https://fe-user-dashboard-staging.firebaseio.com',
      projectId: 'fe-user-dashboard-staging',
      storageBucket: 'fe-user-dashboard-staging.appspot.com',
      messagingSenderId: '626090589086',
      appId: '1:626090589086:web:d2c0425567529a484db48f',
      measurementId: 'G-EVTMD9KRBS',
    }
  }

  const app = initializeApp(firebaseConfig)

  const auth = getAuth(app)

  await signInAnonymously(auth)

  return app
}
