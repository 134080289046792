const labels = {
  CPF: 'CPF',
  fullName: 'Nome completo',
  fullSocialName: 'Nome social completo (opcional)',
  phone: 'Telefone',
  email: 'E-mail',
  zipcode: 'CEP',
  state: 'Estado',
  city: 'Cidade',
  district: 'Bairro',
  street: 'Rua',
  streetNumber: 'Número',
  complement: 'Complemento (opcional)',
}

const placeholders = {
  CPF: '816.799.670-02',
  fullName: 'Cecília Meireles',
  fullSocialName: 'Manu Meireles',
  phone: '(11) 91444-7176',
  email: 'cecilia@email.com.br',
  zipcode: '23530-024',
  city: 'Rio de Janeiro',
  district: 'Rio Comprido',
  street: 'Rua Lourival Lópes',
  streetNumber: '119',
  complement: 'Apto 13',
}

export { labels, placeholders }
