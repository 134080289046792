import * as S from './style'

interface LoadingIconProps {
  iconWidth?: string
}

const LoadingIcon = ({ iconWidth = '24px' }: LoadingIconProps) => {
  return <S.LoadingIconWrapper iconWidth={iconWidth} />
}

export default LoadingIcon
