import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { readIsaSelectProduct, writeIsaSelectProduct } from '~/services/api'
import useDebounce from '~/hooks/useDebounce'
import { IIsaSelectProduct } from '~/types/index'

export const useSelectProduct = () => {
  const { step, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const { productType } = useContext(ParamsContext)
  const [isaCoursesData, setIsaCoursesData] = useState<IIsaSelectProduct | null>(null)
  const [choosedCourseById, setChoosedCourseById] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [searchOffset, setSearchOffset] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const limit = 6

  const debouncedSearch = useDebounce<string>(search, 250)

  const getIsaSelectProduct = useCallback(
    async (newOffset?: number) => {
      const offsetToUse = search ? newOffset ?? searchOffset : newOffset ?? offset

      const result = await readIsaSelectProduct({
        offset: offsetToUse,
        limit,
        courseName: search,
      })

      if (typeof result !== 'string') {
        const choosedCourse = result?.response?.selected ?? null
        choosedCourse && setChoosedCourseById(choosedCourse)

        result?.response?.courses?.length === 1 && setChoosedCourseById(result?.response?.courses?.[0].CourseId)
        setIsaCoursesData(result?.response)
        setHasNextPage(result?.response?.hasNextPage)
      }
    },
    [search, offset, setChoosedCourseById, setIsaCoursesData, setHasNextPage, searchOffset],
  )

  useEffect(() => {
    getIsaSelectProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const handleSelectCourse = useCallback(
    (courseId) => {
      choosedCourseById === courseId ? setChoosedCourseById(null) : setChoosedCourseById(courseId)
    },
    [setChoosedCourseById, choosedCourseById],
  )

  const handleSubmitButton = useCallback(async () => {
    await writeIsaSelectProduct({ currentScreen: step, data: { CourseId: choosedCourseById } })
  }, [choosedCourseById, step])

  const handleOffset = useCallback(
    (type: 'prev' | 'next') => {
      if (type === 'next' && hasNextPage) {
        if (search) {
          const nextSearchOffset = searchOffset + limit
          setSearchOffset(nextSearchOffset)
          getIsaSelectProduct(nextSearchOffset)
          return
        }

        const nextOffset = offset + limit
        setOffset(nextOffset)
        getIsaSelectProduct(nextOffset)
      }

      if (type === 'prev' && offset > 0) {
        if (search) {
          const newSearchOffset = searchOffset - limit
          const nextSearchOffset = newSearchOffset > 0 ? newSearchOffset : 0
          setSearchOffset(nextSearchOffset)
          getIsaSelectProduct(nextSearchOffset)
          return
        }

        const newOffset = offset - limit
        const nextOffset = newOffset > 0 ? newOffset : 0
        setOffset(nextOffset)
        getIsaSelectProduct(nextOffset)
      }
    },
    [setOffset, offset, hasNextPage, getIsaSelectProduct, setSearchOffset, searchOffset, search],
  )

  const handleSearch = useCallback(
    (value) => {
      setSearch(value)
      setSearchOffset(0)
    },
    [setSearch, setSearchOffset],
  )

  return {
    isaCoursesData,
    handleSelectCourse,
    isLoadingData,
    choosedCourseById,
    isSendingData,
    handleSubmitButton,
    productType,
    search,
    handleSearch,
    offset,
    handleOffset,
    limit,
    hasNextPage,
    searchOffset,
  }
}
